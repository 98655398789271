import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import axios from "axios";
import Sidebar from "../partials/Sidebar";
import { toast, ToastContainer } from "react-toastify";
import SendPushNotificationDialog from "../partials/SendPushNotificationDialog";
import moment from "moment"
import DatePicker from 'react-datetime';

import Dropdown from 'react-dropdown';



const deeplinkList= [
    {
        "label": "Product",
         "value": "product"
     },

     {
        "label": "Category",
         "value": "category"
     },

     {
        "label": "Edit Profile",
         "value": "edit_profile"
     },

     {
        "label": "Order",
         "value": "order"
     },

     {
        "label": "Cart",
         "value": "cart"
     },
     {
        "label": "Contact",
         "value": "contact"
     },

     {
        "label": "Wish",
         "value": "wish"
     },

     {
        "label": "Business Card",
         "value": "business_card"
     },

     {
        "label": "Profile",
         "value": "profile"
     },

     {
        "label": "Crop",
         "value": "crop"
     },

     {
        "label": "Refer",
         "value": "refer"
     },

     {
        "label": "Quiz",
         "value": "quiz"
     },

     {
        "label": "Group Product",
         "value": "group_product"
     },
     {
        "label": "POP Add Crop",
         "value": "add_crop"
     },

     {
        "label": "External Link",
         "value": "external_link"
     },
]

const deeplinkValue = "";
class PushNotification extends Component {


    constructor(props) {
        super();
        this.state = {
            title: "",
            description: "",
            imageUrl: "",
            deeplink: "",
            deeplink_id: "",
            userIds: "",
            _id: "",
            comment: "",
            scheduleDateTime:undefined,
            errors: {}
        }
    }

    onDeeplinkChange = e => {
        if(this.state.deeplink != e.value){
            this.state.deeplink_id = "";
        }
        this.state.deeplink = e.value;
        this.setState({})
        console.log(this.state.deeplink)
       }
    

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {

    };

    onScheduleChange= (date) =>{
        this.setState({
            scheduleDateTime: date
        })
    }



    onSubmit = e => {
        e.preventDefault();
        if(this.state.title === "" || this.state.description == ""){
            toast("Fields are Mandatory", {
                position: toast.POSITION.TOP_CENTER,
            })
        }else{
            // this.setState({});
        //     axios
        //     .post("/api/send-push-notification",{ 
        //         title:this.state.title, 
        //         description: this.state.description ,
        //          imageUrl: this.state.imageUrl,
        //         deeplink: this.state.deeplink, 
        //        deeplinkId: this.state.deeplink_id ,
        //        userId: this.state.userIds,
        //        comment: this.state.comment
        // }).then(res=>{
        //         console.log(res.data.message);
        //         if(res.status === 200){
        //             toast(res.data.message, {
        //                 position: toast.POSITION.TOP_CENTER,
        //             })
        //         }
            
        //     }).catch(e=> {
        //         console.log(e);
        //     });
        }
      
    };

    onMinChange = e => {
            this.setState({ [e.target.id]: e.target.value });
    };

    onMaxChange = e => {
            this.setState({ [e.target.id]: e.target.value });
    };  
    
    onValueChange = e=> {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        //const { user } = this.props.auth;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <SendPushNotificationDialog record={this.state} />

                    <div id="page-content-wrapper"   style={{
            margin: '10px 10px 10px 10px'
          }}>
                   <div className="Container-fluid">
                       <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                        <div className="Container">
                            <h2 className="text-primary mt-3">Push Notification</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                              <div className = "row mt-2">
                        
                                <div className = "col-md-3">
                                <label htmlFor="title">Title</label>
                                </div>
                              <div className= "col-md-9">
                               <input
                                    onChange={this.onMinChange}
                                    value={this.state.title}
                                    // error={errors.value}
                                    id="title"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                               </div>
                              </div>

                              <div className = "row mt-2">
                        
                                <div className = "col-md-3">
                                <label htmlFor="description">Description</label>
                                </div>
                              <div className= "col-md-9">
                              <input
                                    onChange={this.onMaxChange}
                                    value={this.state.description}
                                    // error={errors.value}
                                    id="description"
                                
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                               </div>
                              </div>



                              <div className = "row mt-2">
                        
                                    <div className = "col-md-3">
                                    <label htmlFor="imageUrl">Image Url</label>
                                    </div>
                                <div className= "col-md-9">
                                <input
                                    onChange={this.onValueChange}
                                    value={this.state.imageUrl}
                                    // error={errors.value}
                                    id="imageUrl"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                       </div>
                      </div>

                      <div className = "row mt-2">
                        
                        <div className = "col-md-3">
                        <label htmlFor="deeplink">Deeplink</label>
                        </div>
                    <div className= "col-md-9">
                    <Dropdown options={deeplinkList} onChange={this.onDeeplinkChange} value={deeplinkValue} placeholder="Select an option" />



                    {/* <input
                                    onChange={this.onValueChange}
                                    value={this.state.deeplink}
                                    // error={errors.value}
                                    id="deeplink"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                /> */}
                            </div>
                            </div>

                           { (this.state.deeplink === "product" || this.state.deeplink === "category" || this.state.deeplink === "external_link") && ( 
                           <div className = "row mt-2">
                        
                        <div className = "col-md-3">
                        <label htmlFor="deeplink_id">DeepLink Id</label>
                        </div>
                    <div className= "col-md-9">
                    <input
                                    onChange={this.onValueChange}
                                    value={this.state.deeplink_id}
                                    // error={errors.value}
                                    id="deeplink_id"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                            </div>
                            </div>)}



                            <div className = "row mt-2">
                        
                        <div className = "col-md-3">
                        <label htmlFor="userIds">Enter User Ids{" (Comma Separated)"}</label>
                        </div>
                    <div className= "col-md-9">
                    <input
                    placeholder="Eg. 1234,5678,9856"
                                    onChange={this.onValueChange}
                                    value={this.state.userIds}
                                    // error={errors.value}
                                    id="userIds"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                            </div>
                            </div>

                            <div className = "row mt-2">
                        
                        <div className = "col-md-3">
                        <label htmlFor="comment">Comment</label>
                        </div>
                    <div className= "col-md-9">
                    <input
                                    onChange={this.onValueChange}
                                    value={this.state.comment}
                                    // error={errors.value}
                                    id="comment"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                            </div>
                            </div>

                            <div className = "row mt-2">
                        
                        <div className = "col-md-3">
                        <label htmlFor="comment">Schedule Date</label>
                        </div>

                        <div className="col-md-9">
                        <   DatePicker onChange={(date) => this.onScheduleChange(date)} value={this.state.scheduleDateTime} />

                           </div>
                                {/* <div className= "col-md-9">
                                <input
                                    onChange={this.onValueChange}
                                    value={this.state.comment}
                                    // error={errors.value}
                                    id="comment"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                            </div> */}
                            </div>
                            
                                <span className="text-danger">{}</span>
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        data-toggle={this.state.title === "" || this.state.description === "" ? "" : "modal"} data-target={this.state.title === "" || this.state.description === "" ? "" : "#delete-pushnotification-modal"}
                                        className="btn btn-large btn-primary mt-2 px-5">
                                        send
                                    </button>
                                </p>
                            </form>
                        </div>
                </div>
                <ToastContainer />
            </div>
                </div>
            </div>
        );
    }
    
}


PushNotification.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(PushNotification);
