import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

class UserUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            name: this.props.record.name,
            email: this.props.record.email,
            password: '',
            roles: [],
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                name: nextProps.record.name,
                email: nextProps.record.email,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            $('#update-user-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'user-update-name') {
            this.setState({ name: e.target.value });
        }
        if (e.target.id === 'user-update-email') {
            this.setState({ email: e.target.value });
        }
        if (e.target.id === 'user-update-password') {
            this.setState({ password: e.target.value });
        }
    };

    onUserUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            roles: this.state.roles
        };
        axios.post('/api/user-update', newUser, {})
            .then((response) => {
                this.props.getData();
                this.setState({
                    errors: {},
                    name: "",
                    email: "",
                    password: "",
                    roles: [],
                })
                $('#update-user-modal').modal('hide');
                toast(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }, (error) => {
                console.log(error);
            });
    };

    onChangeRoles = (e) => {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        this.setState({
            roles: value
        })
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update User</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="user-update-name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                error={errors.email}
                                                id="user-update-email"
                                                type="email"
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}
                                            />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Role</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChangeRoles} name="roles" id="roles" multiple style={{height:"200px"}}>
                                                <option value="">Please Select</option>
                                                <option value="All">All</option>
                                                <option value="UserV">User(View/Add/Edit)</option>
                                                <option value="UserD">User(Delete)</option>
                                                <option value="NewsV">News(View/Add/Edit)</option>
                                                <option value="NewsD">News(Delete)</option>
                                                <option value="AppDeviceTokenV">AppDeviceToken(View/Add/Edit)</option>
                                                <option value="AppDeviceTokenD">AppDeviceToken(Delete)</option>
                                                <option value="PushNotification">PushNotification</option>
                                                <option value="CropProtectionV">CropProtection(View/Add/Edit)</option>
                                                <option value="CropProtectionD">CropProtection(Delete)</option>
                                                <option value="DiseaseDetectionV">DiseaseDetection(View/Add/Edit)</option>
                                                <option value="DiseaseDetectionD">DiseaseDetection(Delete)</option>
                                                <option value="SoilTestingV">SoilTesting(View/Add/Edit)</option>
                                                <option value="SoilTestingD">SoilTesting(Delete)</option>
                                                <option value="GeodataV">Geodata(View/Add/Edit)</option>
                                                <option value="GeodataD">Geodata(Delete)</option>
                                                <option value="PopV">POP(View/Add/Edit)</option>
                                                <option value="PopD">POP(Delete)</option>
                                                <option value="farmonautV">Farmonaut(View)</option>
                                                <option value="farmonautE">Farmonaut(Add/Edit/Delete)</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                data-reset-input={true}
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                error={errors.password}
                                                id="user-update-password"
                                                type="password"
                                                className={classnames("form-control", {
                                                    invalid: errors.password
                                                })}
                                            />
                                            <span className="text-danger">{errors.password}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UserUpdateModal.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(UserUpdateModal));
