import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
import isEmpty from 'is-empty';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class SendPushnotification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            fromdate: undefined,
            todate: undefined,
            language: undefined,
            title: undefined,
            description: undefined,
            image: undefined,
        };

    }

    onSubmit = (e) => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.language === '' || this.state.language === undefined) {
            errors.language = "This field is required"
        } else {
            delete errors.language
        }
        if (this.state.title === '' || this.state.title === undefined) {
            errors.title = "This field is required"
        } else {
            delete errors.title
        }
        if (this.state.description === '' || this.state.description === undefined) {
            errors.description = "This field is required"
        } else {
            delete errors.description
        }


        if (isEmpty(errors)) {
            // const formData = new FormData();
            // if (this.state.language !== undefined)
            //     formData.append("language", this.state.language);
            // if (this.state.fromdate !== undefined)
            //     formData.append("fromdate", this.state.fromdate);
            // if (this.state.todate !== undefined)
            //     formData.append("todate", this.state.todate);
            const formData = {
                language: this.state.language,
                fromdate: this.state.fromdate,
                todate: this.state.todate,
                description: this.state.description,
                title: this.state.title,
                image: this.state.image
            };
            axios.post('/api/send-push', formData)
                .then((response) => {
                    this.setState({
                        errors: {},
                        fromdate: undefined,
                        todate: undefined,
                        title: undefined,
                        description: undefined,
                        image: undefined,
                    })
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    setFromDate = (date) => {
        this.setState({ fromdate: date });
    }

    setToDate = (date) => {
        this.setState({ todate: date });
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Send Push Notification</h1>
                            <form noValidate onSubmit={this.onSubmit} id="send-push">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Push Notification Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.title}
                                            id="title"
                                            type="text"
                                            error={errors.title}
                                            className={classnames("form-control", {
                                                invalid: errors.title
                                            })} />
                                        <span className="text-danger">{errors.title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Push Notification Description</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.description}
                                            id="description"
                                            type="text"
                                            error={errors.description}
                                            className={classnames("form-control", {
                                                invalid: errors.description
                                            })} />
                                        <span className="text-danger">{errors.description}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Push Notification Image URL</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.image}
                                            id="image"
                                            type="text"
                                            error={errors.image}
                                            className={classnames("form-control", {
                                                invalid: errors.image
                                            })} />
                                        <span className="text-danger">{errors.image}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">From Date</label>
                                    </div>
                                    <div className="col-md-9">

                                        <DatePicker maxDate={moment().toDate()} selected={this.state.fromdate} placeholderText="MM/DD/YYYY" onChange={(date) => this.setFromDate(date)} className="form-control" />
                                        <span className="text-danger">{errors.dispense_date}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">To Date</label>
                                    </div>
                                    <div className="col-md-9">

                                        <DatePicker maxDate={moment().toDate()} selected={this.state.todate} placeholderText="MM/DD/YYYY" onChange={(date) => this.setToDate(date)} className="form-control" />
                                        <span className="text-danger">{errors.dispense_date}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="language">Language</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select onChange={this.onChange} name="language" id="language">
                                            <option value="">Please Select</option>
                                            <option value="en">English</option>
                                            <option value="hi">Hindi</option>
                                            <option value="mr">Marathi</option>
                                            <option value="te">Telugu</option>
                                            <option value="pa">Punjabi</option>
                                        </select>
                                        <span className="text-danger">{errors.language}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-9">
                                    </div>
                                    <div className="col-md-3"></div>
                                    <button
                                        form="send-push"
                                        type="submit"
                                        className="btn btn-primary">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(SendPushnotification);
