import React from 'react'
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { EditorState,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
class CropProtectionUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            category_id: "",
            status: "Active",
            heading: "",
            crop: "",
            video_url: "",
            sub_heading: "",
            image: "",
            crop_description_heading: "",
            crop_description_detail: "",
            symptoms_heading: "",
            symptoms_detail: "",
            preventive_measure_heading: "",
            preventive_measure_detail: "",
            control_measure_heading: "",
            control_measure_detail: "",
            recommended_products: "",
            heading_hi: "",
            video_url_hi: "",
            sub_heading_hi: "",
            image_hi: "",
            crop_description_heading_hi: "",
            crop_description_detail_hi: "",
            symptoms_heading_hi: "",
            symptoms_detail_hi: "",
            preventive_measure_heading_hi: "",
            preventive_measure_detail_hi: "",
            control_measure_heading_hi: "",
            control_measure_detail_hi: "",
            recommended_products_hi: "",
            heading_te: "",
            video_url_te: "",
            sub_heading_te: "",
            image_te: "",
            crop_description_heading_te: "",
            crop_description_detail_te: "",
            symptoms_heading_te: "",
            symptoms_detail_te: "",
            preventive_measure_heading_te: "",
            preventive_measure_detail_te: "",
            control_measure_heading_te: "",
            control_measure_detail_te: "",
            recommended_products_te: "",
            heading_mr: "",
            video_url_mr: "",
            sub_heading_mr: "",
            image_mr: "",
            crop_description_heading_mr: "",
            crop_description_detail_mr: "",
            symptoms_heading_mr: "",
            symptoms_detail_mr: "",
            preventive_measure_heading_mr: "",
            preventive_measure_detail_mr: "",
            control_measure_heading_mr: "",
            control_measure_detail_mr: "",
            recommended_products_mr: "",
            heading_pa: "",
            video_url_pa: "",
            sub_heading_pa: "",
            image_pa: "",
            crop_description_heading_pa: "",
            crop_description_detail_pa: "",
            symptoms_heading_pa: "",
            symptoms_detail_pa: "",
            preventive_measure_heading_pa: "",
            preventive_measure_detail_pa: "",
            control_measure_heading_pa: "",
            control_measure_detail_pa: "",
            recommended_products_pa: "",
            editorState_eng: "",
            editorState_hi: "",
            editorState_pa: "",
            editorState_mr: "",
            editorState_te: "",
            editorState_symptoms_eng: "",
            editorState_symptoms_hi: "",
            editorState_symptoms_pa: "",
            editorState_symptoms_mr: "",
            editorState_symptoms_te: "",
            editorState_preventive_eng: "",
            editorState_preventive_hi: "",
            editorState_preventive_pa: "",
            editorState_preventive_mr: "",
            editorState_preventive_te: "",
            editorState_control_eng: "",
            editorState_control_hi: "",
            editorState_control_pa: "",
            editorState_control_mr: "",
            editorState_control_te: "",
            errors: {},
            gallery: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (!_.isEqual(this.props.record, nextProps.record)) {
            // console.log(nextProps.record)

            const crop_description_detail_eng = nextProps.record.crop_description_detail;
            
            if (crop_description_detail_eng) {
                const contentBlock_eng = htmlToDraft(crop_description_detail_eng);
              const contentState_eng = ContentState.createFromBlockArray(contentBlock_eng.contentBlocks);
              const editorState_eng = EditorState.createWithContent(contentState_eng);
              this.setState({
                editorState_eng,
              });
            }

            const crop_description_detail_te_text = nextProps.record.crop_description_detail_te;
            
            if (crop_description_detail_te_text) {
              const contentBlock_te = htmlToDraft(crop_description_detail_te_text);
              const contentState_te = ContentState.createFromBlockArray(contentBlock_te.contentBlocks);
              const editorState_te = EditorState.createWithContent(contentState_te);
              this.setState({
                editorState_te,
              });
            }

            const crop_description_detail_hi_text = nextProps.record.crop_description_detail_hi;
            
            if (crop_description_detail_hi_text) {
              const contentBlock_hi = htmlToDraft(crop_description_detail_hi_text);
              const contentState_hi = ContentState.createFromBlockArray(contentBlock_hi.contentBlocks);
              const editorState_hi = EditorState.createWithContent(contentState_hi);
              this.setState({
                editorState_hi,
              });
            }

            const crop_description_detail_pa_text = nextProps.record.crop_description_detail_pa;

            if (crop_description_detail_pa_text) {
              const contentBlock_pa= htmlToDraft(crop_description_detail_pa_text);
              const contentState_pa = ContentState.createFromBlockArray(contentBlock_pa.contentBlocks);
              const editorState_pa= EditorState.createWithContent(contentState_pa);
              this.setState({
                editorState_pa,
              });
            }

            const crop_description_detail_mr_text = nextProps.record.crop_description_detail_mr;
            if (crop_description_detail_mr_text) {
              const contentBlock_mr = htmlToDraft(crop_description_detail_mr_text);
              const contentState_mr = ContentState.createFromBlockArray(contentBlock_mr.contentBlocks);
              const editorState_mr = EditorState.createWithContent(contentState_mr);
              this.setState({
                editorState_mr,
              });
            }

            const symptoms_detail_eng = nextProps.record.symptoms_detail;
            if (symptoms_detail_eng) {
              const contentBlock = htmlToDraft(symptoms_detail_eng);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_symptoms_eng = EditorState.createWithContent(contentState);
              this.setState({
                editorState_symptoms_eng,
              });
            }

            const symptoms_detail_hi_text = nextProps.record.symptoms_detail_hi;
            if (symptoms_detail_hi_text) {
              const contentBlock = htmlToDraft(symptoms_detail_hi_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_symptoms_hi = EditorState.createWithContent(contentState);
              this.setState({
                editorState_symptoms_hi,
              });
            }
            const symptoms_detail_pa_text = nextProps.record.symptoms_detail_pa;
            if (symptoms_detail_pa_text) {
              const contentBlock = htmlToDraft(symptoms_detail_pa_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_symptoms_pa = EditorState.createWithContent(contentState);
              this.setState({
                editorState_symptoms_pa,
              });
            }
            const symptoms_detail_mr_text = nextProps.record.symptoms_detail_mr;
            if (symptoms_detail_mr_text) {
              const contentBlock = htmlToDraft(symptoms_detail_mr_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_symptoms_mr = EditorState.createWithContent(contentState);
              this.setState({
                editorState_symptoms_mr,
              });
            }
            const symptoms_detail_te_text = nextProps.record.symptoms_detail_te;
            if (symptoms_detail_te_text) {
              const contentBlock = htmlToDraft(symptoms_detail_te_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_symptoms_te = EditorState.createWithContent(contentState);
              this.setState({
                editorState_symptoms_te,
              });
            }
            const preventive_detail_eng_text = nextProps.record.preventive_measure_detail;
            if (preventive_detail_eng_text) {
              const contentBlock = htmlToDraft(preventive_detail_eng_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_eng = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_eng,
              });
            }

            const preventive_detail_hi_text = nextProps.record.preventive_measure_detail_hi;
            if (preventive_detail_hi_text) {
              const contentBlock = htmlToDraft(preventive_detail_hi_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_hi = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_hi,
              });
            }


            const preventive_detail_pa_text = nextProps.record.preventive_measure_detail_pa;
            if (preventive_detail_pa_text) {
              const contentBlock = htmlToDraft(preventive_detail_pa_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_pa = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_pa,
              });
            }

            const preventive_detail_mr_text = nextProps.record.preventive_measure_detail_mr;
            if (preventive_detail_mr_text) {
              const contentBlock = htmlToDraft(preventive_detail_mr_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_mr = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_mr,
              });
            }

            const preventive_detail_te_text = nextProps.record.preventive_measure_detail_te;
            if (preventive_detail_te_text) {
              const contentBlock = htmlToDraft(preventive_detail_te_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_te = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_te,
              });
            }

            const control_measure_detail_eng_text = nextProps.record.control_measure_detail;
            if (control_measure_detail_eng_text) {
              const contentBlock = htmlToDraft(control_measure_detail_eng_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_measure_eng = EditorState.createWithContent(contentState);
              this.setState({
                editorState_measure_eng,
              });
            }

            const control_measure_detail_hi_text = nextProps.record.control_measure_detail_hi;
            if (control_measure_detail_hi_text) {
              const contentBlock = htmlToDraft(control_measure_detail_hi_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_hi = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_hi,
              });
            }

            const control_measure_detail_te_text = nextProps.record.control_measure_detail_te;
            if (control_measure_detail_te_text) {
              const contentBlock = htmlToDraft(control_measure_detail_te_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_te = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_te,
              });
            }

            const control_measure_detail_mr_text = nextProps.record.control_measure_detail_mr;
            if (control_measure_detail_mr_text) {
              const contentBlock = htmlToDraft(control_measure_detail_mr_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_mr = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_mr,
              });
            }
            const control_measure_detail_pa_text = nextProps.record.control_measure_detail_pa;
            if (control_measure_detail_pa_text) {
              const contentBlock = htmlToDraft(control_measure_detail_pa_text);
              const contentState= ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const editorState_preventive_pa = EditorState.createWithContent(contentState);
              this.setState({
                editorState_preventive_pa,
              });
            }



            this.setState({
                ...nextProps.record,
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-news-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onEditorStateChange_eng = (editorState_eng) => {
        this.setState({
          editorState_eng,
        });
    };
    onEditorStateChange_hi = (editorState_hi) => {
        this.setState({
          editorState_hi,
        });
    };

    onEditorStateChange_pa = (editorState_pa) => {
        this.setState({
          editorState_pa,
        });
    };

    onEditorStateChange_te = (editorState_te) => {
        this.setState({
          editorState_te,
        });
    };
    onEditorStateChange_mr = (editorState_mr) => {
        this.setState({
          editorState_mr,
        });
    };


    onEditorStateChange_symptoms_eng = (editorState_symptoms_eng) => {
        this.setState({
          editorState_symptoms_eng,
        });
    };
    onEditorStateChange_symptoms_hi = (editorState_symptoms_hi) => {
        this.setState({
          editorState_symptoms_hi,
        });
    };

    onEditorStateChange_symptoms_pa = (editorState_symptoms_pa) => {
        this.setState({
          editorState_symptoms_pa,
        });
    };

    onEditorStateChange_symptoms_te = (editorState_symptoms_te) => {
        this.setState({
          editorState_symptoms_te,
        });
    };
    onEditorStateChange_symptoms_mr = (editorState_symptoms_mr) => {
        this.setState({
          editorState_symptoms_mr,
        });
    };

    onEditorStateChange_preventive_eng = (editorState_preventive_eng) => {
        this.setState({
          editorState_preventive_eng,
        });
    };
    onEditorStateChange_preventive_hi = (editorState_preventive_hi) => {
        this.setState({
          editorState_preventive_hi,
        });
    };

    onEditorStateChange_preventive_pa = (editorState_preventive_pa) => {
        this.setState({
          editorState_preventive_pa,
        });
    };

    onEditorStateChange_preventive_te = (editorState_preventive_te) => {
        this.setState({
          editorState_preventive_te,
        });
    };
    onEditorStateChange_preventive_mr = (editorState_preventive_mr) => {
        this.setState({
          editorState_preventive_mr,
        });
    };


    onEditorStateChange_control_eng = (editorState_control_eng) => {
        this.setState({
          editorState_control_eng,
        });
    };
    onEditorStateChange_control_hi = (editorState_control_hi) => {
        this.setState({
          editorState_control_hi,
        });
    };

    onEditorStateChange_control_pa = (editorState_control_pa) => {
        this.setState({
          editorState_control_pa,
        });
    };

    onEditorStateChange_control_te = (editorState_control_te) => {
        this.setState({
          editorState_control_te,
        });
    };
    onEditorStateChange_control_mr = (editorState_control_mr) => {
        this.setState({
          editorState_control_mr,
        });
    };





    onNewsUpdate = e => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.heading === '') {
            errors.heading = "This field is required"
        } else {
            delete errors.heading
        }
        if (this.state.crop === '') {
            errors.crop = "This field is required"
        } else {
            delete errors.crop
        }
        if (this.state.category_id === '') {
            errors.category_id = "This field is required"
        } else {
            delete errors.category_id
        }



        if (isEmpty(errors)) {
            const formData = new FormData();
            formData.append("_id", this.state._id);
            if (this.state.gallery.length > 0) {
                for (const key of Object.keys(this.state.gallery)) {
                    formData.append('galleryImages', this.state.gallery[key])
                }
            }
            if (this.state.image !== undefined && this.state.sub_heading !== undefined)
                formData.append("image", this.state.image);
            if (this.state.heading !== undefined && this.state.heading !== undefined)
                formData.append("heading", this.state.heading);
            if (this.state.crop !== undefined && this.state.crop !== undefined)
                formData.append("crop", this.state.crop);
            if (this.state.sub_heading !== undefined && this.state.sub_heading !== undefined)
                formData.append("sub_heading", this.state.sub_heading);
            if (this.state.crop_description_heading !== undefined && this.state.crop_description_heading !== undefined)
                formData.append("crop_description_heading", this.state.crop_description_heading);
            if (this.state.editorState_eng!=="" && stateToHTML(this.state.editorState_eng.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_eng.getCurrentContent()) !== '')
                formData.append("crop_description_detail", stateToHTML(this.state.editorState_eng.getCurrentContent()));
            if (this.state.symptoms_heading !== undefined && this.state.symptoms_heading !== undefined)
                formData.append("symptoms_heading", this.state.symptoms_heading);
            if (this.state.preventive_measure_heading !== undefined && this.state.preventive_measure_heading !== undefined)
                formData.append("preventive_measure_heading", this.state.preventive_measure_heading);
            if (this.state.control_measure_heading !== undefined && this.state.control_measure_heading !== undefined)
                formData.append("control_measure_heading", this.state.control_measure_heading);
            if (this.state.recommended_products !== undefined && this.state.recommended_products !== undefined)
                formData.append("recommended_products", this.state.recommended_products);
            if (this.state.video_url !== undefined && this.state.video_url !== undefined)
                formData.append("video_url", this.state.video_url);
            if (this.state.image_hi !== undefined && this.state.image_hi !== undefined)
                formData.append("image_hi", this.state.image_hi);
            if (this.state.heading_hi !== undefined && this.state.heading_hi !== undefined)
                formData.append("heading_hi", this.state.heading_hi);
            if (this.state.sub_heading_hi !== undefined && this.state.sub_heading_hi !== undefined)
                formData.append("sub_heading_hi", this.state.sub_heading_hi);
            if (this.state.crop_description_heading_hi !== undefined && this.state.crop_description_heading_hi !== undefined)
                formData.append("crop_description_heading_hi", this.state.crop_description_heading_hi);
            if (this.state.editorState_hi!=="" && stateToHTML(this.state.editorState_hi.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_hi.getCurrentContent()) !== undefined)
                formData.append("crop_description_detail_hi", stateToHTML(this.state.editorState_hi.getCurrentContent()));
            if (this.state.symptoms_heading_hi !== undefined && this.state.symptoms_heading_hi !== undefined)
                formData.append("symptoms_heading_hi", this.state.symptoms_heading_hi);
            if (this.state.preventive_measure_heading_hi !== undefined && this.state.preventive_measure_heading_hi !== undefined)
                formData.append("preventive_measure_heading_hi", this.state.preventive_measure_heading_hi);
            if (this.state.control_measure_heading_hi !== undefined && this.state.control_measure_heading_hi !== undefined)
                formData.append("control_measure_heading_hi", this.state.control_measure_heading_hi);
            if (this.state.recommended_products_hi !== undefined && this.state.recommended_products_hi !== undefined)
                formData.append("recommended_products_hi", this.state.recommended_products_hi);
            if (this.state.video_url_hi !== undefined && this.state.video_url_hi !== undefined)
                formData.append("video_url_hi", this.state.video_url_hi);
            if (this.state.image_te !== undefined && this.state.image_te !== undefined)
                formData.append("image_te", this.state.image_te);
            if (this.state.heading_te !== undefined && this.state.heading_te !== undefined)
                formData.append("heading_te", this.state.heading_te);
            if (this.state.sub_heading_te !== undefined && this.state.sub_heading_te !== undefined)
                formData.append("sub_heading_te", this.state.sub_heading_te);
            if (this.state.crop_description_heading_te !== undefined && this.state.crop_description_heading_te !== undefined)
                formData.append("crop_description_heading_te", this.state.crop_description_heading_te);
            
           if (this.state.editorState_te!=="" && stateToHTML(this.state.editorState_te.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_te.getCurrentContent()) !== undefined)
                formData.append("crop_description_detail_te", stateToHTML(this.state.editorState_te.getCurrentContent()));
           
            if (this.state.symptoms_heading_te !== undefined && this.state.symptoms_heading_te !== undefined)
                formData.append("symptoms_heading_te", this.state.symptoms_heading_te);
            if (this.state.preventive_measure_heading_te !== undefined && this.state.preventive_measure_heading_te !== undefined)
                formData.append("preventive_measure_heading_te", this.state.preventive_measure_heading_te);
            if (this.state.control_measure_heading_te !== undefined && this.state.control_measure_heading_te !== undefined)
                formData.append("control_measure_heading_te", this.state.control_measure_heading_te);
            if (this.state.recommended_products_te !== undefined && this.state.recommended_products_te !== undefined)
                formData.append("recommended_products_te", this.state.recommended_products_te);
            if (this.state.video_url_te !== undefined && this.state.video_url_te !== undefined)
                formData.append("video_url_te", this.state.video_url_te);
            if (this.state.image_mr !== undefined && this.state.image_mr !== undefined)
                formData.append("image_mr", this.state.image_mr);
            if (this.state.heading_mr !== undefined && this.state.heading_mr !== undefined)
                formData.append("heading_mr", this.state.heading_mr);
            if (this.state.sub_heading_mr !== undefined && this.state.sub_heading_mr !== undefined)
                formData.append("sub_heading_mr", this.state.sub_heading_mr);
            if (this.state.crop_description_heading_mr !== undefined && this.state.crop_description_heading_mr !== undefined)
                formData.append("crop_description_heading_mr", this.state.crop_description_heading_mr);

           if (this.state.editorState_mr!=="" && stateToHTML(this.state.editorState_mr.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_mr.getCurrentContent()) !== undefined)
                formData.append("crop_description_detail_mr", stateToHTML(this.state.editorState_mr.getCurrentContent()));
           
    
            if (this.state.symptoms_heading_mr !== undefined && this.state.symptoms_heading_mr !== undefined)
                formData.append("symptoms_heading_mr", this.state.symptoms_heading_mr);
            if (this.state.preventive_measure_heading_mr !== undefined && this.state.preventive_measure_heading_mr !== undefined)
                formData.append("preventive_measure_heading_mr", this.state.preventive_measure_heading_mr);
            if (this.state.control_measure_heading_mr !== undefined && this.state.control_measure_heading_mr !== undefined)
                formData.append("control_measure_heading_mr", this.state.control_measure_heading_mr);
            if (this.state.recommended_products_mr !== undefined && this.state.recommended_products_mr !== undefined)
                formData.append("recommended_products_mr", this.state.recommended_products_mr);
            if (this.state.video_url_mr !== undefined && this.state.video_url_mr !== undefined)
                formData.append("video_url_mr", this.state.video_url_mr);
            if (this.state.image_pa !== undefined && this.state.image_pa !== undefined)
                formData.append("image_pa", this.state.image_pa);
            if (this.state.heading_pa !== undefined && this.state.heading_pa !== undefined)
                formData.append("heading_pa", this.state.heading_pa);
            if (this.state.sub_heading_pa !== undefined && this.state.sub_heading_pa !== undefined)
                formData.append("sub_heading_pa", this.state.sub_heading_pa);
            if (this.state.crop_description_heading_pa !== undefined && this.state.crop_description_heading_pa !== undefined)
                formData.append("crop_description_heading_pa", this.state.crop_description_heading_pa);
            
            if (this.state.editorState_pa!=="" && stateToHTML(this.state.editorState_pa.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_pa.getCurrentContent()) !== undefined)
                formData.append("crop_description_detail_pa", stateToHTML(this.state.editorState_pa.getCurrentContent()));
           
            if (this.state.symptoms_heading_pa !== undefined && this.state.symptoms_heading_pa !== undefined)
                formData.append("symptoms_heading_pa", this.state.symptoms_heading_pa);
            if (this.state.preventive_measure_heading_pa !== undefined && this.state.preventive_measure_heading_pa !== undefined)
                formData.append("preventive_measure_heading_pa", this.state.preventive_measure_heading_pa);
            if (this.state.control_measure_heading_pa !== undefined && this.state.control_measure_heading_pa !== undefined)
                formData.append("control_measure_heading_pa", this.state.control_measure_heading_pa);
            if (this.state.recommended_products_pa !== undefined && this.state.recommended_products_pa !== undefined)
                formData.append("recommended_products_pa", this.state.recommended_products_pa);
            if (this.state.video_url_pa !== undefined && this.state.video_url_pa !== undefined)
                formData.append("video_url_pa", this.state.video_url_pa);

            if (this.state.category_id !== undefined && this.state.category_id !== undefined)
                formData.append("category_id", this.state.category_id);


            if (this.state.editorState_symptoms_eng!=="" && stateToHTML(this.state.editorState_symptoms_eng.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_symptoms_eng.getCurrentContent()) !== undefined)
                formData.append("symptoms_detail", stateToHTML(this.state.editorState_symptoms_eng.getCurrentContent()));
            if (this.state.editorState_symptoms_te!=="" && stateToHTML(this.state.editorState_symptoms_te.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_symptoms_te.getCurrentContent()) !== undefined)
                formData.append("symptoms_detail_te", stateToHTML(this.state.editorState_symptoms_te.getCurrentContent()));
            if (this.state.editorState_symptoms_hi!=="" && stateToHTML(this.state.editorState_symptoms_hi.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_symptoms_hi.getCurrentContent()) !== undefined)
                formData.append("symptoms_detail_hi", stateToHTML(this.state.editorState_symptoms_hi.getCurrentContent()));
            if (this.state.editorState_symptoms_mr!=="" && stateToHTML(this.state.editorState_symptoms_mr.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_symptoms_mr.getCurrentContent()) !== undefined)
                formData.append("symptoms_detail_mr", stateToHTML(this.state.editorState_symptoms_mr.getCurrentContent()));
            if (this.state.editorState_symptoms_pa!=="" && stateToHTML(this.state.editorState_symptoms_pa.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_symptoms_pa.getCurrentContent()) !== undefined)
                formData.append("symptoms_detail_pa", stateToHTML(this.state.editorState_symptoms_pa.getCurrentContent()));
          
            if (this.state.editorState_preventive_eng!=="" && stateToHTML(this.state.editorState_preventive_eng.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_preventive_eng.getCurrentContent()) !== undefined)
                formData.append("preventive_measure_detail", stateToHTML(this.state.editorState_preventive_eng.getCurrentContent()));
            if (this.state.editorState_preventive_te!=="" && stateToHTML(this.state.editorState_preventive_te.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_preventive_te.getCurrentContent()) !== undefined)
                formData.append("preventive_measure_detail_te", stateToHTML(this.state.editorState_preventive_te.getCurrentContent()));
            if (this.state.editorState_preventive_hi!=="" && stateToHTML(this.state.editorState_preventive_hi.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_preventive_hi.getCurrentContent()) !== undefined)
                formData.append("preventive_measure_detail_hi", stateToHTML(this.state.editorState_preventive_hi.getCurrentContent()));
            if (this.state.editorState_preventive_mr!=="" && stateToHTML(this.state.editorState_preventive_mr.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_preventive_mr.getCurrentContent()) !== undefined)
                formData.append("preventive_measure_detail_mr", stateToHTML(this.state.editorState_preventive_mr.getCurrentContent()));
            if (this.state.editorState_preventive_pa!=="" && stateToHTML(this.state.editorState_preventive_pa.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_preventive_pa.getCurrentContent()) !== undefined)
                formData.append("preventive_measure_detail_pa", stateToHTML(this.state.editorState_preventive_pa.getCurrentContent()));
        
            if (this.state.editorState_control_eng!=="" && stateToHTML(this.state.editorState_control_eng.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_control_eng.getCurrentContent()) !== undefined)
                formData.append("control_measure_detail", stateToHTML(this.state.editorState_control_eng.getCurrentContent()));
            if (this.state.editorState_control_te!=="" && stateToHTML(this.state.editorState_control_te.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_control_te.getCurrentContent()) !== undefined)
                formData.append("control_measure_detail_te", stateToHTML(this.state.editorState_control_te.getCurrentContent()));
            if (this.state.editorState_control_hi!=="" && stateToHTML(this.state.editorState_control_hi.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_control_hi.getCurrentContent()) !== undefined)
                formData.append("control_measure_detail_hi", stateToHTML(this.state.editorState_control_hi.getCurrentContent()));
            if (this.state.editorState_control_mr!=="" && stateToHTML(this.state.editorState_control_mr.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_control_mr.getCurrentContent()) !== undefined)
                formData.append("control_measure_detail_mr", stateToHTML(this.state.editorState_control_mr.getCurrentContent()));
            if (this.state.editorState_control_pa!=="" && stateToHTML(this.state.editorState_control_pa.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_control_pa.getCurrentContent()) !== undefined)
                formData.append("control_measure_detail_pa", stateToHTML(this.state.editorState_control_pa.getCurrentContent()));

            formData.append("status", this.state.status);


            axios.post('/api/crop-update', formData, {})
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        heading: "",
                        crop: "",
                        status: "Active",
                        category_id: "",
                        video_url: "",
                        sub_heading: "",
                        image: "",
                        crop_description_heading: "",
                        crop_description_detail: "",
                        symptoms_heading: "",
                        symptoms_detail: "",
                        preventive_measure_heading: "",
                        preventive_measure_detail: "",
                        control_measure_heading: "",
                        control_measure_detail: "",
                        recommended_products: "",
                        heading_hi: "",
                        video_url_hi: "",
                        sub_heading_hi: "",
                        image_hi: "",
                        crop_description_heading_hi: "",
                        crop_description_detail_hi: "",
                        symptoms_heading_hi: "",
                        symptoms_detail_hi: "",
                        preventive_measure_heading_hi: "",
                        preventive_measure_detail_hi: "",
                        control_measure_heading_hi: "",
                        control_measure_detail_hi: "",
                        recommended_products_hi: "",
                        heading_te: "",
                        video_url_te: "",
                        sub_heading_te: "",
                        image_te: "",
                        crop_description_heading_te: "",
                        crop_description_detail_te: "",
                        symptoms_heading_te: "",
                        symptoms_detail_te: "",
                        preventive_measure_heading_te: "",
                        preventive_measure_detail_te: "",
                        control_measure_heading_te: "",
                        control_measure_detail_te: "",
                        recommended_products_te: "",
                        heading_mr: "",
                        video_url_mr: "",
                        sub_heading_mr: "",
                        image_mr: "",
                        crop_description_heading_mr: "",
                        crop_description_detail_mr: "",
                        symptoms_heading_mr: "",
                        symptoms_detail_mr: "",
                        preventive_measure_heading_mr: "",
                        preventive_measure_detail_mr: "",
                        control_measure_heading_mr: "",
                        control_measure_detail_mr: "",
                        recommended_products_mr: "",
                        heading_pa: "",
                        video_url_pa: "",
                        sub_heading_pa: "",
                        image_pa: "",
                        crop_description_heading_pa: "",
                        crop_description_detail_pa: "",
                        symptoms_heading_pa: "",
                        symptoms_detail_pa: "",
                        preventive_measure_heading_pa: "",
                        preventive_measure_detail_pa: "",
                        control_measure_heading_pa: "",
                        control_measure_detail_pa: "",
                        recommended_products_pa: "",
                        editorState_eng: "",
                        editorState_hi: "",
                        editorState_pa: "",
                        editorState_mr: "",
                        editorState_te: "",
                        editorState_symptoms_eng: "",
                        editorState_symptoms_hi: "",
                        editorState_symptoms_pa: "",
                        editorState_symptoms_mr: "",
                        editorState_symptoms_te: "",
                        editorState_preventive_eng: "",
                        editorState_preventive_hi: "",
                        editorState_preventive_pa: "",
                        editorState_preventive_mr: "",
                        editorState_preventive_te: "",
                        editorState_control_eng: "",
                        editorState_control_hi: "",
                        editorState_control_pa: "",
                        editorState_control_mr: "",
                        editorState_control_te: "",
                        gallery: []
                    })
                    $('#update-crop-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    onMultipleFileUpload = event => {
        this.setState({ gallery: [...this.state.gallery, ...event.target.files] })
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        const { errors } = this.state;
        const { editorState_eng } = this.state;
        const { editorState_hi } = this.state;
        const { editorState_pa } = this.state;
        const { editorState_te } = this.state;
        const { editorState_mr } = this.state;
        const { editorState_symptoms_eng } = this.state;
        const { editorState_symptoms_hi } = this.state;
        const { editorState_symptoms_pa } = this.state;
        const { editorState_symptoms_te } = this.state;
        const { editorState_symptoms_mr } = this.state;
        const { editorState_preventive_eng } = this.state;
        const { editorState_preventive_hi } = this.state;
        const { editorState_preventive_pa } = this.state;
        const { editorState_preventive_te } = this.state;
        const { editorState_preventive_mr } = this.state;
        const { editorState_control_eng } = this.state;
        const { editorState_control_hi } = this.state;
        const { editorState_control_pa } = this.state;
        const { editorState_control_te } = this.state;
        const { editorState_control_mr } = this.state;
        return (
            <div>
                <div className="modal" id="update-crop-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Crop Protection</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onNewsUpdate} id="add-crop" encType="multipart/form-data">

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading">Category Id</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category_id}
                                                id="category_id"
                                                type="text"
                                                error={errors.category_id}
                                                className={classnames("form-control", {
                                                    invalid: errors.category_id
                                                })} />
                                            <span className="text-danger">{errors.category_id}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading">Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.heading}
                                                id="heading"
                                                type="text"
                                                error={errors.heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.heading
                                                })} />
                                            <span className="text-danger">{errors.heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop">Crop</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="crop"
                                                type="text"
                                                error={errors.crop}
                                                value={this.state.crop}
                                                className={classnames("form-control", {
                                                    invalid: errors.crop
                                                })} />
                                            <span className="text-danger">{errors.crop}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sub_heading">Sub Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sub_heading"
                                                type="text"
                                                error={errors.sub_heading}
                                                value={this.state.sub_heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading
                                                })} />
                                            <span className="text-danger">{errors.sub_heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Video Url(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url"
                                                type="text"
                                                error={errors.video_url}
                                                value={this.state.video_url}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url
                                                })} />
                                            <span className="text-danger">{errors.video_url}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image"
                                                type="file"
                                                error={errors.image}
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })} />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Crop Description Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_description_heading}
                                                error={errors.crop_description_heading}
                                                id="crop_description_heading"
                                                type="description"
                                                className={classnames("form-control", {
                                                    invalid: errors.crop_description_heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.crop_description_heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Crop Description Detail(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                         <Editor
                                            value={this.state.crop_description_detail}
                                            editorState={editorState_eng}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_eng}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Symptoms Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="symptoms_heading"
                                                type="text"
                                                value={this.state.symptoms_heading}
                                                error={errors.symptoms_heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.symptoms_heading
                                                })} />
                                            <span className="text-danger">{errors.symptoms_heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Symptoms Detail(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor
                                            value={this.state.symptoms_detail}
                                            editorState={editorState_symptoms_eng}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_symptoms_eng}
                                         />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Preventive Measure Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.preventive_measure_heading}
                                                error={errors.preventive_measure_heading}
                                                id="preventive_measure_heading"
                                                type="preventive_measure_heading"
                                                className={classnames("form-control", {
                                                    invalid: errors.preventive_measure_heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.preventive_measure_heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Preventive Measure Detail(English)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <Editor
                                            value={this.state.preventive_measure_detail}
                                            editorState={editorState_preventive_eng}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_preventive_eng}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Heading(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="control_measure_heading"
                                                type="text"
                                                value={this.state.control_measure_heading}
                                                error={errors.control_measure_heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.control_measure_heading
                                                })} />
                                            <span className="text-danger">{errors.control_measure_heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Detail(English)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <Editor
                                            value={this.state.control_measure_detail}
                                            editorState={editorState_control_eng}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_control_eng}
                                         />
                                        
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Recommended Products(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products
                                                })} id="recommended_products"  
                                        value={this.state.recommended_products} 
                                        onChange={this.onChange} />
                                          

                                            
                                            
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading_hi">Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.heading_hi}
                                                id="heading_hi"
                                                type="text"
                                                error={errors.heading_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.heading_hi
                                                })} />
                                            <span className="text-danger">{errors.heading_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sub_heading_hi">Sub Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sub_heading_hi"
                                                type="text"
                                                value={this.state.sub_heading_hi}
                                                error={errors.sub_heading_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_hi
                                                })} />
                                            <span className="text-danger">{errors.sub_heading_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_hi">Video Url(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_hi"
                                                type="text"
                                                value={this.state.video_url_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_hi
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="image_hi">Image(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_hi"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_hi
                                                })} />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_heading_hi">Crop Description Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">


                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_description_heading_hi}
                                                id="crop_description_heading_hi"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_hi
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_detail_hi">Crop Description Detail(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                         <Editor
                                            value={this.state.crop_description_detail_hi}
                                            editorState={editorState_hi}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_hi}
                                         />
                                          
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_heading_hi">Symptoms Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="symptoms_heading_hi"
                                                type="text"
                                                value={this.state.symptoms_heading_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_hi
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_detail_hi">Symptoms Detail(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_detail_hi}
                                                editorState={editorState_symptoms_hi}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_symptoms_hi}
                                             />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_heading_hi">Preventive Measure Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.preventive_measure_heading_hi}
                                                error={errors.preventive_measure_heading_hi}
                                                id="preventive_measure_heading_hi"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.preventive_measure_heading
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_detail_hi">Preventive Measure Detail(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                            value={this.state.preventive_measure_detail_hi}
                                            editorState={editorState_preventive_hi}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_preventive_hi}
                                         />
                                    
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="control_measure_heading_hi">Control Measure Heading(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="control_measure_heading_hi"
                                                type="text"
                                                error={errors.control_measure_heading_hi}
                                                value={this.state.control_measure_heading_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.control_measure_heading_hi
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Detail(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <Editor
                                            value={this.state.control_measure_detail_hi}
                                            editorState={editorState_control_hi}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_control_hi}
                                         />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="recommended_products_hi">Recommended Products(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products_hi
                                                })} id="recommended_products_hi"  
                                        value={this.state.recommended_products_hi} 
                                        onChange={this.onChange} />
                                        
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading_te">Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.heading_te}
                                                id="heading_te"
                                                type="text"
                                                error={errors.heading_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.heading_te
                                                })} />
                                            <span className="text-danger">{errors.heading_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sub_heading_te">Sub Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sub_heading_te"
                                                type="text"
                                                error={errors.sub_heading_te}
                                                value={this.state.sub_heading_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_te
                                                })} />
                                            <span className="text-danger">{errors.sub_heading_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_te">Video Url(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_te"
                                                type="text"
                                                value={this.state.video_url_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_te
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="image_te">Image(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_te"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_te
                                                })} />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_heading_te">Crop Description Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_description_heading_te}
                                                id="crop_description_heading_te"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_te
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_detail_te">Crop Description Detail(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">

                                         <Editor
                                            value={this.state.crop_description_detail_te}
                                            editorState={editorState_te}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_te}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_heading_te">Symptoms Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="symptoms_heading_te"
                                                type="text"
                                                value={this.state.symptoms_heading_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_te
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_detail_te">Symptoms Detail(Telugu)</label>
                                        </div>


                                        <div className="col-md-9">
                                            <Editor
                                            value={this.state.symptoms_detail_te}
                                            editorState={editorState_symptoms_te}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_symptoms_te}
                                            />
            
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_heading_te">Preventive Measure Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.preventive_measure_heading_te}
                                                error={errors.preventive_measure_heading_te}
                                                id="preventive_measure_heading_te"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.preventive_measure_heading
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_detail_te">Preventive Measure Detail(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor
                                            value={this.state.preventive_measure_detail_te}
                                            editorState={editorState_preventive_te}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_preventive_te}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="control_measure_heading_te">Control Measure Heading(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="control_measure_heading_te"
                                                type="text"
                                                value={this.state.control_measure_heading_te}
                                                error={errors.control_measure_heading_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.control_measure_heading_te
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Detail(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor
                                            value={this.state.control_measure_detail_te}
                                            editorState={editorState_control_te}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_control_te}
                                         />

                                    
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="recommended_products_te">Recommended Products(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products_te
                                                })} id="recommended_products_te"  
                                            value={this.state.recommended_products_te} 
                                            onChange={this.onChange} />
                                        
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading_mr">Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.heading_mr}
                                                id="heading_mr"
                                                type="text"
                                                error={errors.heading_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.heading_mr
                                                })} />
                                            <span className="text-danger">{errors.heading_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sub_heading_mr">Sub Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sub_heading_mr"
                                                type="text"
                                                value={this.state.sub_heading_mr}
                                                error={errors.sub_heading_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_mr
                                                })} />
                                            <span className="text-danger">{errors.sub_heading_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_mr">Video Url(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_mr"
                                                type="text"
                                                value={this.state.video_url_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_mr
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="image_mr">Image(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_mr"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.image_mr
                                                })} />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_heading_mr">Crop Description Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_description_heading_mr}
                                                id="crop_description_heading_mr"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.crop_description_heading_mr
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_detail_mr">Crop Description Detail(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">

                                         <Editor
                                            value={this.state.crop_description_detail_mr}
                                            editorState={editorState_mr}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_mr}
                                         />
                                           
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_heading_mr">Symptoms Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="symptoms_heading_mr"
                                                type="text"
                                                value={this.state.symptoms_heading_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.symptoms_heading_mr
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_detail_mr">Symptoms Detail(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor
                                            value={this.state.symptoms_detail_mr}
                                            editorState={editorState_symptoms_mr}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_symptoms_mr}
                                         />

                            
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_heading_mr">Preventive Measure Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.preventive_measure_heading_mr}
                                                error={errors.preventive_measure_heading_mr}
                                                id="preventive_measure_heading_mr"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.preventive_measure_heading
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_detail_mr">Preventive Measure Detail(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <Editor
                                            value={this.state.preventive_measure_detail_mr}
                                            editorState={editorState_preventive_mr}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_preventive_mr}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="control_measure_heading_mr">Control Measure Heading(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="control_measure_heading_mr"
                                                type="text"
                                                value={this.state.control_measure_heading_mr}
                                                error={errors.control_measure_heading_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.control_measure_heading_mr
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Detail(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">

                                          <Editor
                                            value={this.state.control_measure_detail_mr}
                                            editorState={editorState_control_mr}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_control_mr}
                                         />

                                    
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="recommended_products_mr">Recommended Products(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                          <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products_mr
                                                })} id="recommended_products_mr"  
                                        value={this.state.recommended_products_mr} 
                                        onChange={this.onChange} />
                                    
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="heading_pa">Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.heading_pa}
                                                id="heading_pa"
                                                type="text"
                                                error={errors.heading_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.heading_pa
                                                })} />
                                            <span className="text-danger">{errors.heading_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sub_heading_pa">Sub Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sub_heading_pa"
                                                type="text"
                                                value={this.state.sub_heading_pa}
                                                error={errors.sub_heading_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_pa
                                                })} />
                                            <span className="text-danger">{errors.sub_heading_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_pa">Video Url(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_pa"
                                                type="text"
                                                value={this.state.video_url_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_pa
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="image_pa">Image(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_pa"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_pa
                                                })} />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_heading_pa">Crop Description Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_description_heading_pa}
                                                id="crop_description_heading_pa"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_pa
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop_description_detail_pa">Crop Description Detail(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">

                                         <Editor
                                            value={this.state.crop_description_detail_pa}
                                            editorState={editorState_pa}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_pa}
                                         />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_heading_pa">Symptoms Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="symptoms_heading_pa"
                                                type="text"
                                                value={this.state.symptoms_heading_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_heading_pa
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symptoms_detail_pa">Symptoms Detail(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor
                                            value={this.state.symptoms_detail_pa}
                                            editorState={editorState_symptoms_pa}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_symptoms_pa}
                                         />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_heading_pa">Preventive Measure Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.preventive_measure_heading_pa}
                                                error={errors.preventive_measure_heading_pa}
                                                id="preventive_measure_heading_pa"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.preventive_measure_heading
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="preventive_measure_detail_pa">Preventive Measure Detail(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <Editor
                                            value={this.state.preventive_measure_detail_pa}
                                            editorState={editorState_preventive_pa}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_preventive_pa}
                                         />
                                    
                                
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="control_measure_heading_pa">Control Measure Heading(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="control_measure_heading_pa"
                                                type="text"
                                                value={this.state.control_measure_heading_pa}
                                                error={errors.control_measure_heading_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.control_measure_heading_pa
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Control Measure Detail(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">


                                        <Editor
                                            value={this.state.control_measure_detail_pa}
                                            editorState={editorState_control_pa}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.onEditorStateChange_control_pa}
                                         />

                                
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="recommended_products_pa">Recommended Products(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                         <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products_pa
                                                })} id="recommended_products_pa"  
                                        value={this.state.recommended_products_pa} 
                                        onChange={this.onChange} />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="gallery">Gallery(Multiple)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onMultipleFileUpload}
                                                error={errors.gallery}
                                                id="gallery"
                                                type="file"
                                                multiple
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChangeSelect} name="status" id="status">
                                                <option value="">Please Select</option>
                                                <option selected={this.state.status === "Active" ? "selected" : ""} value="Active">Active</option>
                                                <option selected={this.state.status === "Inactive" ? "selected" : ""} value="Inactive">Inactive</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    type="submit"
                                    onClick={this.onNewsUpdate}
                                    className="btn btn-primary">
                                    Update Crop Protection
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(CropProtectionUpdateModal));
