import React from 'react'
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
class DiseaseMasterAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            crop: "",
            disease: "",
            fst_name: "",
            title: "",
            editorState_description: "",
            disease_hi: "",
            fst_name_hi: "",
            title_hi: "",
            editorState_description_hi: "",
            disease_te: "",
            fst_name_te: "",
            title_te: "",
            editorState_description_te: "",
            disease_mr: "",
            fst_name_mr: "",
            title_mr: "",
            editorState_description_mr: "",
            disease_pa: "",
            fst_name_pa: "",
            title_pa: "",
            editorState_description_pa: "",
            recommended_products: "",
            errors: {},
        };
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onEditorStateChange_description = (editorState_description) => {
        this.setState({
            editorState_description,
        });
    };
    onEditorStateChange_description_hi = (editorState_description_hi) => {
        this.setState({
            editorState_description_hi,
        });
    };
    onEditorStateChange_description_te = (editorState_description_te) => {
        this.setState({
            editorState_description_te,
        });
    };
    onEditorStateChange_description_mr = (editorState_description_mr) => {
        this.setState({
            editorState_description_mr,
        });
    };
    onEditorStateChange_description_pa = (editorState_description_pa) => {
        this.setState({
            editorState_description_pa,
        });
    };

    onAdd = e => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.crop === '') {
            errors.crop = "This field is required"
        } else {
            delete errors.crop
        }
        if (this.state.title === '') {
            errors.title = "This field is required"
        } else {
            delete errors.title
        }
        if (this.state.disease === '') {
            errors.disease = "This field is required"
        } else {
            delete errors.disease
        }
        if (isEmpty(errors)) {
            const formData = {
                _id: this.state._id,
                crop: this.state.crop,
                disease: this.state.disease,
                fst_name: this.state.fst_name,
                description: this.state.description,
                title: this.state.title,
                recommended_products: this.state.recommended_products,
                disease_hi: this.state.disease_hi,
                fst_name_hi: this.state.fst_name_hi,
                title_hi: this.state.title_hi,
                description_hi: this.state.description_hi,
                disease_te: this.state.disease_te,
                fst_name_te: this.state.fst_name_te,
                title_te: this.state.title_te,
                description_te: this.state.description_te,
                disease_mr: this.state.disease_mr,
                fst_name_mr: this.state.fst_name_mr,
                title_mr: this.state.title_mr,
                description_mr: this.state.description_mr,
                disease_pa: this.state.disease_pa,
                fst_name_pa: this.state.fst_name_pa,
                title_pa: this.state.title_pa,
                description_pa: this.state.description_pa,
            }
            if (this.state.editorState_description != "" && stateToHTML(this.state.editorState_description.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_description.getCurrentContent()) !== undefined) {
                formData.description = stateToHTML(this.state.editorState_description.getCurrentContent())
            }
            if (this.state.editorState_description_hi != "" && stateToHTML(this.state.editorState_description_hi.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_description_hi.getCurrentContent()) !== undefined) {
                formData.description_hi = stateToHTML(this.state.editorState_description_hi.getCurrentContent())
            }
            if (this.state.editorState_description_te != "" && stateToHTML(this.state.editorState_description_te.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_description_te.getCurrentContent()) !== undefined) {
                formData.description_te = stateToHTML(this.state.editorState_description_te.getCurrentContent())
            }
            if (this.state.editorState_description_mr != "" && stateToHTML(this.state.editorState_description_mr.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_description_mr.getCurrentContent()) !== undefined) {
                formData.description_mr = stateToHTML(this.state.editorState_description_mr.getCurrentContent())
            }
            if (this.state.editorState_description_pa != "" && stateToHTML(this.state.editorState_description_pa.getCurrentContent()) !== undefined && stateToHTML(this.state.editorState_description_pa.getCurrentContent()) !== undefined) {
                formData.description_pa = stateToHTML(this.state.editorState_description_pa.getCurrentContent())
            }
            axios.post('/api/disease-master-add', formData, {})
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        crop: "",
                        disease: "",
                        fst_name: "",
                        title: "",
                        editorState_description: "",
                        recommended_products: "",
                        disease_hi: "",
                        fst_name_hi: "",
                        title_hi: "",
                        editorState_description_hi: "",
                        disease_te: "",
                        fst_name_te: "",
                        title_te: "",
                        editorState_description_te: "",
                        disease_mr: "",
                        fst_name_mr: "",
                        title_mr: "",
                        editorState_description_mr: "",
                        disease_pa: "",
                        fst_name_pa: "",
                        title_pa: "",
                        description_pa: "",
                    })
                    $('#add-diseasemaster-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        const { errors } = this.state;
        const { editorState_description } = this.state;
        const { editorState_description_hi } = this.state;
        const { editorState_description_pa } = this.state;
        const { editorState_description_te } = this.state;
        const { editorState_description_mr } = this.state;
        return (
            <div>
                <div className="modal" id="add-diseasemaster-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Disease Master</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAdd} id="add-disease" encType="multipart/form-data">

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="crop">Crop</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select name="crop" className="form-control" onChange={this.onChange} id="crop">
                                                <option>Please Select</option>
                                                {this.props.cropData.map(each => {
                                                    return (
                                                        <option value={each.id}>{each.name}</option>
                                                    )
                                                })}
                                            </select>
                                            <span className="text-danger">{errors.crop}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="disease">Disease</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.disease}
                                                id="disease"
                                                type="text"
                                                error={errors.disease}
                                                className={classnames("form-control", {
                                                    invalid: errors.disease
                                                })} />
                                            <span className="text-danger">{errors.disease}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fst_name">FST Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fst_name}
                                                id="fst_name"
                                                type="text"
                                                error={errors.fst_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.fst_name
                                                })} />
                                            <span className="text-danger">{errors.fst_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_description}
                                                editorState={editorState_description}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_description}
                                            />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="disease_hi">Disease Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.disease_hi}
                                                id="disease_hi"
                                                type="text"
                                                error={errors.disease_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.disease_hi
                                                })} />
                                            <span className="text-danger">{errors.disease_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fst_name_hi">FST Name Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fst_name_hi}
                                                id="fst_name_hi"
                                                type="text"
                                                error={errors.fst_name_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.fst_name_hi
                                                })} />
                                            <span className="text-danger">{errors.fst_name_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title_hi">Title Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_hi}
                                                id="title_hi"
                                                type="text"
                                                error={errors.title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_hi
                                                })} />
                                            <span className="text-danger">{errors.title_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_hi">Description Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_description_hi}
                                                editorState={editorState_description_hi}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_description_hi}
                                            />
                                            <span className="text-danger">{errors.description_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="disease_te">Disease Telegu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.disease_te}
                                                id="disease_te"
                                                type="text"
                                                error={errors.disease_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.disease_te
                                                })} />
                                            <span className="text-danger">{errors.disease_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fst_name_te">FST Name Telegu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fst_name_te}
                                                id="fst_name_te"
                                                type="text"
                                                error={errors.fst_name_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.fst_name_te
                                                })} />
                                            <span className="text-danger">{errors.fst_name_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title_te">Title Telegu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_te}
                                                id="title_te"
                                                type="text"
                                                error={errors.title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_te
                                                })} />
                                            <span className="text-danger">{errors.title_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_te">Description Telegu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_description_te}
                                                editorState={editorState_description_te}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_description_te}
                                            />
                                            <span className="text-danger">{errors.description_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="disease_mr">Disease Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.disease_mr}
                                                id="disease_mr"
                                                type="text"
                                                error={errors.disease_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.disease_mr
                                                })} />
                                            <span className="text-danger">{errors.disease_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fst_name_mr">FST Name Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fst_name_mr}
                                                id="fst_name_mr"
                                                type="text"
                                                error={errors.fst_name_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.fst_name_mr
                                                })} />
                                            <span className="text-danger">{errors.fst_name_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title_mr">Title Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_mr}
                                                id="title_mr"
                                                type="text"
                                                error={errors.title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_mr
                                                })} />
                                            <span className="text-danger">{errors.title_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_mr">Description Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_description_mr}
                                                editorState={editorState_description_mr}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_description_mr}
                                            />
                                            <span className="text-danger">{errors.description_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="disease_pa">Disease Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.disease_pa}
                                                id="disease_pa"
                                                type="text"
                                                error={errors.disease_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.disease_pa
                                                })} />
                                            <span className="text-danger">{errors.disease_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fst_name_pa">FST Name Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fst_name_pa}
                                                id="fst_name_pa"
                                                type="text"
                                                error={errors.fst_name_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.fst_name_pa
                                                })} />
                                            <span className="text-danger">{errors.fst_name_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title_pa">Title Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_pa}
                                                id="title_pa"
                                                type="text"
                                                error={errors.title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_pa
                                                })} />
                                            <span className="text-danger">{errors.title_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_pa">Description Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.symptoms_description_pa}
                                                editorState={editorState_description_pa}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange_description_pa}
                                            />
                                            <span className="text-danger">{errors.description_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="recommended_products">Recommended Products(Comma Separated)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.recommended_products}
                                                id="recommended_products"
                                                type="text"
                                                error={errors.recommended_products}
                                                className={classnames("form-control", {
                                                    invalid: errors.recommended_products
                                                })} />
                                            <span className="text-danger">{errors.recommended_products}</span>
                                        </div>
                                    </div>
                                </form>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    type="submit"
                                    onClick={this.onAdd}
                                    className="btn btn-primary">
                                    Add Disease Master
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(DiseaseMasterAddModal));
