import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Multiselect from 'multiselect-react-dropdown';

class NewsAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            status: "Active",
            title: undefined,
            video_url: undefined,
            description: undefined,
            image: undefined,
            title_hi: undefined,
            image_hi: undefined,
            video_url_hi: undefined,
            description_hi: undefined,
            title_te: undefined,
            image_te: undefined,
            video_url_te: undefined,
            description_te: undefined,
            title_mr: undefined,
            image_mr: undefined,
            video_url_mr: undefined,
            description_mr: undefined,
            title_pa: undefined,
            image_pa: undefined,
            video_url_pa: undefined,
            errors: {},
            options: [{ label: 'English', value: 1 }, { label: 'Hindi', value: 2 }]
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-news-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onUserAdd = e => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.title === '') {
            errors.title = "This field is required"
        } else {
            delete errors.title
        }
        if (this.state.description === '') {
            errors.description = "This field is required"
        } else {
            delete errors.description
        }


        if (isEmpty(errors)) {
            const formData = new FormData();
            if (this.state.image !== undefined)
                formData.append("image", this.state.image);
            if (this.state.image_hi !== undefined)
                formData.append("image_hi", this.state.image_hi);
            if (this.state.image_te !== undefined)
                formData.append("image_te", this.state.image_te);
            if (this.state.image_mr !== undefined)
                formData.append("image_mr", this.state.image_mr);
            if (this.state.image_pa !== undefined)
                formData.append("image_pa", this.state.image_pa);
            if (this.state.title !== undefined)
                formData.append("title", this.state.title);
            if (this.state.title_hi !== undefined)
                formData.append("title_hi", this.state.title_hi);
            if (this.state.title_te !== undefined)
                formData.append("title_te", this.state.title_te);
            if (this.state.title_mr !== undefined)
                formData.append("title_mr", this.state.title_mr);
            if (this.state.title_pa !== undefined)
                formData.append("title_pa", this.state.title_pa);
            if (this.state.description !== undefined)
                formData.append("description", this.state.description);
            if (this.state.description_hi !== undefined)
                formData.append("description_hi", this.state.description_hi);
            if (this.state.description_te !== undefined)
                formData.append("description_te", this.state.description_te);
            if (this.state.description_mr !== undefined)
                formData.append("description_mr", this.state.description_mr);
            if (this.state.description_pa !== undefined)
                formData.append("description_pa", this.state.description_pa);
            if (this.state.video_url !== undefined)
                formData.append("video_url", this.state.video_url);
            if (this.state.video_url_hi !== undefined)
                formData.append("video_url_hi", this.state.video_url_hi);
            if (this.state.video_url_te !== undefined)
                formData.append("video_url_te", this.state.video_url_te);
            if (this.state.video_url_mr !== undefined)
                formData.append("video_url_mr", this.state.video_url_mr);
            if (this.state.video_url_pa !== undefined)
                formData.append("video_url_pa", this.state.video_url_pa);

            // formData.append("status", this.state.status == 1 ? 1 : 0);
            formData.append("status", this.state.status);
            axios.post('/api/news-add', formData, {})
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        status: "Active",
                        title: undefined,
                        video_url: undefined,
                        description: undefined,
                        image: undefined,
                        title_hi: undefined,
                        image_hi: undefined,
                        video_url_hi: undefined,
                        description_hi: undefined,
                        title_te: undefined,
                        image_te: undefined,
                        video_url_te: undefined,
                        description_te: undefined,
                        title_mr: undefined,
                        image_mr: undefined,
                        video_url_mr: undefined,
                        description_mr: undefined,
                        title_pa: undefined,
                        image_pa: undefined,
                        video_url_pa: undefined,
                        description_pa: undefined,
                    })
                    $('#add-news-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-news-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add News</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUserAdd} id="add-news" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image"
                                                type="file"
                                                error={errors.image}
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })} />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Video URL(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url"
                                                type="text"
                                                error={errors.video_url}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url
                                                })} />
                                            <span className="text-danger">{errors.video_url}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Description(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                error={errors.description}
                                                id="description"
                                                type="description"
                                                className={classnames("form-control", {
                                                    invalid: errors.description
                                                })}
                                            />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_hi}
                                                id="title_hi"
                                                type="text"
                                                error={errors.title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_hi
                                                })} />
                                            <span className="text-danger">{errors.title_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_hi"
                                                type="file"
                                                error={errors.image_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_hi
                                                })} />
                                            <span className="text-danger">{errors.image_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Video URL(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_hi"
                                                type="text"
                                                error={errors.video_url_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_hi
                                                })} />
                                            <span className="text-danger">{errors.video_url_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Description(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description_hi}
                                                error={errors.description_hi}
                                                id="description_hi"
                                                type="description_hi"
                                                className={classnames("form-control", {
                                                    invalid: errors.description_hi
                                                })}
                                            />
                                            <span className="text-danger">{errors.description_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_te}
                                                id="title_te"
                                                type="text"
                                                error={errors.title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_te
                                                })} />
                                            <span className="text-danger">{errors.title_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_te"
                                                type="file"
                                                error={errors.image_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_te
                                                })} />
                                            <span className="text-danger">{errors.image_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_te">Video URL(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_te"
                                                type="text"
                                                error={errors.video_url_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_te
                                                })} />
                                            <span className="text-danger">{errors.video_url_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Description(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description_te}
                                                error={errors.description_te}
                                                id="description_te"
                                                type="description_te"
                                                className={classnames("form-control", {
                                                    invalid: errors.description_te
                                                })}
                                            />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_mr}
                                                id="title_mr"
                                                type="text"
                                                error={errors.title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_mr
                                                })} />
                                            <span className="text-danger">{errors.title_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_mr"
                                                type="file"
                                                error={errors.image_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_mr
                                                })} />
                                            <span className="text-danger">{errors.image_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Video URL(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_mr"
                                                type="text"
                                                error={errors.video_url_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_mr
                                                })} />
                                            <span className="text-danger">{errors.video_url_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Description(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description_mr}
                                                error={errors.description_mr}
                                                id="description_mr"
                                                type="description_mr"
                                                className={classnames("form-control", {
                                                    invalid: errors.description_mr
                                                })}
                                            />
                                            <span className="text-danger">{errors.description_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_pa}
                                                id="title_pa"
                                                type="text"
                                                error={errors.title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_pa
                                                })} />
                                            <span className="text-danger">{errors.title_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="image_pa"
                                                type="file"
                                                error={errors.image_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_pa
                                                })} />
                                            <span className="text-danger">{errors.image_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Video URL(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_pa"
                                                type="text"
                                                error={errors.video_url_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_pa
                                                })} />
                                            <span className="text-danger">{errors.video_url_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Description(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description_pa}
                                                error={errors.description_pa}
                                                id="description_pa"
                                                type="description_pa"
                                                className={classnames("form-control", {
                                                    invalid: errors.description_pa
                                                })}
                                            />
                                            <span className="text-danger">{errors.description_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} name="status" id="status">
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-news"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add News
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NewsAddModal.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(NewsAddModal));
