import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $, { error } from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

class HomePageCardUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            status: "Active",
            title : "",
            title_hi : "",
            title_te : "",
            title_mr : "",
            title_pa : "",
            image: "",
            image_hi: "",
            image_te: "",
            image_mr: "",
            image_pa: "",
            deeplink: "",
            deeplink_hi: "",
            deeplink_te: "",
            deeplink_mr: "",
            deeplink_pa: "",
            sowing_date: "",
            type: "external_link",
            openLinkType: "0",
            name: "",
            errors: {},
            options: [{ label: 'English', value: 1 }, { label: 'Hindi', value: 2 }]
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (!_.isEqual(this.props.record, nextProps.record)) {
          this.setState({
            status: "Active",
            title : "",
            title_hi : "",
            title_te : "",
            title_mr : "",
            title_pa : "",
            image: "",
            image_hi: "",
            image_te: "",
            image_mr: "",
            image_pa: "",
            deeplink: "",
            deeplink_hi: "",
            deeplink_te: "",
            deeplink_mr: "",
            deeplink_pa: "",
            sowing_date: "",
            type: "external_link",
            name: "",
            openLinkType: "0",
            errors: {},
            options: [{ label: 'English', value: 1 }, { label: 'Hindi', value: 2 }]
          })
            console.log(nextProps.record)
            this.setState({
                ...nextProps.record,
            });
       }
       this.setState({})
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#update-homepage-card-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onCardUpdate = e => {
        e.preventDefault();
        console.log("73 working");
        let { errors } = this.state
        if (this.state.name === '') {
            errors.name = "This field is required"
        } else {
            delete errors.name
        }
        if (this.state.title === '') {
            errors.title = "This field is required"
        } else {
            delete errors.title
        }

       if (this.state.deeplink === '') {
            errors.deeplink = "This field is required"
        } else {
            delete errors.deeplink
        }

        if (this.state.image === '') {
            errors.image = "This field is required"
        } else {
            delete errors.image
        }
        console.log("97 working", isEmpty(errors))


        if (isEmpty(errors)) {
            axios.post('/api/homepage-card-update', {
                _id: this.state._id,
                 name: this.state.name,
                 type: this.state.type,
                 status: this.state.status == "Active" ? true : false,

                 title: this.state.title,
                 title_hi: this.state.title_hi,
                 title_mr: this.state.title_mr,
                 title_te: this.state.title_te,
                 title_pa: this.state.title_pa,

                 image: this.state.image,
                 image_hi: this.state.image_hi,
                 image_te: this.state.image_te,
                 image_mr: this.state.image_mr,
                 image_pa: this.state.image_pa,

                 deeplink: this.state.deeplink,
                 deeplink_hi: this.state.deeplink_hi,
                 deeplink_te: this.state.deeplink_te,
                 deeplink_mr: this.state.deeplink_mr,
                 deeplink_pa: this.state.deeplink_pa,
                 openLinkType: this.state.openLinkType,

                      }, {})
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        status:"Active",
                        title : "",
                        title_hi : "",
                        title_te : "",
                        title_mr : "",
                        title_pa : "",
                        image: "",
                        image_hi: "",
                        image_te: "",
                        image_mr: "",
                        image_pa: "",
                        type: "external_link",
                        deeplink: "",
                        deeplink_hi: "",
                        deeplink_te: "",
                        deeplink_mr: "",
                        deeplink_pa: "",
                        name: "",
                    })
                    $('#update-homepage-card-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-homepage-card-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Card</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCardUpdate} id="update-card" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.category}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="type">Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} name="type" id="type">
                                                <option value="external_link">External Link</option>
                                            </select>
                                            <span className="text-danger">{errors.type}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.category_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_hi}
                                                id="title_hi"
                                                type="text"
                                                error={errors.title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_hi
                                                })} />
                                            <span className="text-danger">{errors.title_hi}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title Telugu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_te}
                                                id="title_te"
                                                type="text"
                                                error={errors.title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_te
                                                })} />
                                            <span className="text-danger">{errors.title_te}</span>
                                        </div>
                                    </div>


                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_mr}
                                                id="title_mr"
                                                type="text"
                                                error={errors.title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_mr
                                                })} />
                                            <span className="text-danger">{errors.title_mr}</span>
                                        </div>
                                    </div>
                                                       
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_pa}
                                                id="title_pa"
                                                type="text"
                                                error={errors.title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_pa
                                                })} />
                                            <span className="text-danger">{errors.title_pa}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.image}
                                                id="image"
                                                type="text"
                                                error={errors.image}
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })} />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>
                                  
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.image_hi}
                                                id="image_hi"
                                                type="text"
                                                error={errors.image_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_hi
                                                })} />
                                            <span className="text-danger">{errors.image_hi}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image Telugu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.image_te}
                                                id="image_te"
                                                type="text"
                                                error={errors.image_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_te
                                                })} />
                                            <span className="text-danger">{errors.image_te}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image Marthi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.image_mr}
                                                id="image_mr"
                                                type="text"
                                                error={errors.image_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_mr
                                                })} />
                                            <span className="text-danger">{errors.image_mr}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.image_pa}
                                                id="image_pa"
                                                type="text"
                                                error={errors.image_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.image_pa
                                                })} />
                                            <span className="text-danger">{errors.image_pa}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.deeplink}
                                                id="deeplink"
                                                type="text"
                                                error={errors.deeplink}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink
                                                })} />
                                            <span className="text-danger">{errors.deeplink}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink Hindi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.deeplink_hi}
                                                id="deeplink_hi"
                                                type="text"
                                                error={errors.deeplink_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink_hi
                                                })} />
                                            <span className="text-danger">{errors.deeplink_hi}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink Telugu</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.deeplink_te}
                                                id="deeplink_te"
                                                type="text"
                                                error={errors.deeplink_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink_te
                                                })} />
                                            <span className="text-danger">{errors.deeplink_te}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink Marathi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.deeplink_mr}
                                                id="deeplink_mr"
                                                type="text"
                                                error={errors.deeplink_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink_mr
                                                })} />
                                            <span className="text-danger">{errors.deeplink_mr}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink Punjabi</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.deeplink_pa}
                                                id="deeplink_pa"
                                                type="text"
                                                error={errors.deeplink_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink_pa
                                                })} />
                                            <span className="text-danger">{errors.deeplink_pa}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Open Link into</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} name="openLinkType" id="openLinkType">
                                                <option value="0">Kisanwala App</option>
                                                <option value="1">Other</option>
                                            </select>
                                            <span className="text-danger">{errors.openLinkType}</span>
                                        </div>
                                    </div>
                
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} name="status" id="status">
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-card"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// HomePageCardUpdateModal.propTypes = {
//     addUser: PropTypes.func.isRequired,
//     auth: PropTypes.object.isRequired,
//     errors: PropTypes.object.isRequired
// };

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(HomePageCardUpdateModal));
