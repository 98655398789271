import React from 'react'
import _ from "lodash";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Loader from "./loader"

class SendPushNotificationDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            se_mobile : "",
            isLoading: false
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.record, nextProps.record)) {
            this.setState({
                ...nextProps.record,
            });
       }
            if (!_.isEqual(this.props.record, nextProps.record)) {
                this.setState({
                    ...nextProps.record,
                });
           }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#delete-pushnotification-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    sendNotification= e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        axios
        .post("/api/send-push-notification",{ 
            title:this.state.title, 
            description: this.state.description ,
             imageUrl: this.state.imageUrl,
            deeplink: this.state.deeplink, 
           deeplinkId: this.state.deeplink_id ,
           userId: this.state.userIds,
           comment: this.state.comment,
           scheduleDateTime: this.state.scheduleDateTime,
    }).then(res=>{
        this.setState({
            isLoading: false
        })
            console.log(res.data.message);
            if(res.status === 200){
                $('#delete-pushnotification-modal').modal('hide');
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
            if(res.status === 201){
                $('#delete-pushnotification-modal').modal('hide');
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        
        }).catch(e=> {
            console.log(e);
            this.setState({
                isLoading: false
            })
        });

    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="delete-pushnotification-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        
                            <div className="modal-body">
                             
                            </div>
                            <h6 className="mt-2 text-primary">Are you sure want to Send Notification?</h6>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                               {(this.state.isLoading) &&
                               
                                <Loader></Loader>
                           }
                                {(!this.state.isLoading) &&  
                                <button
                                    form="add-pop"
                                    type="submit"
                                    onClick={this.sendNotification} 
                                    className="btn btn-primary">
                                    Confirm
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    

}
SendPushNotificationDialog.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(SendPushNotificationDialog));