import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import DeleteGeoDataDialog from "../partials/DeleteGeoDataDialog";
import ResumePauseGeoDataModal from "../partials/ResumePauseGeoDataModal";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from "moment"



const options = [
    'All', 'Farmer', 'SE'
  ];
const defaultOption = options[0];

const fieldStatuses= [
    'All', 'Active', 'Paused', 'Deleted'
]

const defaultStatus = fieldStatuses[0];

class Geodata extends Component {
  

    constructor(props) {
        super(props);

        this.farmerMobileColumn= {
            key: "mobile",
            text: "Farmer Mobile",
            className: "mobile",
            align: "left",
            sortable: true,
        }

        this.farmerNameColumn=  {
            key: "farmer_name",
            text: "Farmer Name",
            className: "farmer_name",
            align: "left",
            sortable: true,
        }

        this.seNameColumn = {
            key: "se_name",
            text: "SE Name",
            className: "se_name",
            align: "left",
            sortable: true,
        }

        this.seMobileColumn = {
            key: "se_mobile",
            text: "SE Mobile",
            className: "se_mobile",
            align: "left",
            sortable: true
        }

        this.fieldNameColumn = {
            key: "name",
            text: "Field Name",
            className: "name",
            align: "left",
            sortable: true,
        }

        this.fieldIdColumn =  {
            key: "FieldID",
            text: "FieldID",
            className: "FieldID",
            align: "left",
            sortable: false
        }

        this.fieldAreaColumn = {
            key: "FieldArea",
            text: "FieldArea",
            className: "FieldArea",
            align: "left",
            sortable: false
        }

        this.dateColumn = {
            key: "date",
            text: "Date",
            className: "date",
            align: "left",
            sortable: true
        }

        this.actionColumn= {
            key: "action",
            text: "Action",
            className: "action",
            width: 150,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            data-toggle="modal"
                            data-target="#update-disease-modal"
                            className="btn btn-primary btn-sm"
                            onClick={() => this.editRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                        </button> */}
                        {(this.props.auth.user.roles.includes("GeodataD") || this.props.auth.user.roles.includes("All")) &&
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}
                                data-toggle={record.isDeleted ? "" : "modal"} data-target={record.isDeleted ? "" : "#delete-geodata-modal"}
                                style={{ backgroundColor: record.isDeleted ? "grey" : "red", marginRight: '5px' }}
                                >
                                <i className="fa fa-trash"></i>
                            </button>
                        }

                        {(this.props.auth.user.roles.includes("GeodataD") || this.props.auth.user.roles.includes("All")) &&
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.resumeField(record)}
                                data-toggle={record.isPaused != true ||  record.isDeleted  == true? "" : "modal"} data-target={record.isPaused != true || record.isDeleted == true? "" : "#pause-geodata-modal"}
                                style={{ backgroundColor: record.isPaused != true ? "grey" : "" , marginRight: '5px'}}
                                >
                                <i className="fa fa-play"></i>
                            </button>
                        }                   

                       

                        {(this.props.auth.user.roles.includes("GeodataD") || this.props.auth.user.roles.includes("All")) &&
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.pauseField(record)}
                                data-toggle={record.isPaused ? "" : "modal"} data-target={record.isPaused ||  record.isDeleted  == true ? "" : "#pause-geodata-modal"}
                                style={{ backgroundColor: record.isPaused  || record.isDeleted  == true?  "grey" : "" , marginRight: '5px'}}
                                >
                                <i className="fa fa-pause"></i>
                            </button>
                        }


        
                        
                    </Fragment>
                );
            }
        }

        this.activeStatusColumn={
                key: "activeStatus",
                text: "Active Status",
                className: "activeStatus",
                width: 100,
                align: "left",
                sortable: true,
              
            }







        // this.options = [
        //     'one', 'two', 'three'
        //   ];
        this.columns = [
            this.farmerMobileColumn,

             this.farmerNameColumn,
            
            this.seNameColumn,
            
            this.seMobileColumn,

            this.fieldNameColumn,
           
            this.fieldIdColumn ,
            
            this.fieldAreaColumn,
            
            this.dateColumn,
        
            this.actionColumn,

            this.activeStatusColumn,
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Geodata",
            no_data_text: 'No Geodata found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            currentRecord: {
                id: '',
                devicetoken: '',
                customertype: '',
            },
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date_formatted", order: "desc" }
            },
            totalCount: 0,
            mobile: undefined,
            se_mobile: undefined,
            date: undefined,
            userType: "All",
            fieldStatus: "All"
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.se_mobile !== '' && { se_mobile: this.state.se_mobile }),
            ...(this.state.mobile !== '' && { mobile: this.state.mobile }),
            ...(this.state.date !== '' && { date: this.state.date }),
            ...({user_type: this.state.userType}),
            ...({field_status: this.state.fieldStatus})
        }
        axios
            .post("/api/geodata", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    each.date = each.date ? moment(each.date).format('DD-MM-YYYY HH:mm:ss') : ""
                    each.FieldArea = each.geodata ? (parseInt(each.geodata.FieldArea)/4046.86).toFixed(2) : ""
                    each.FieldID = each.geodata ? each.geodata.FieldID : ""
                    each.isDeleted = each.is_deleted
                    each.isPaused = each.is_paused
                    each.activeStatus= each.is_paused ? "Paused" : each.is_deleted ? "Deleted": "Active"
                    result.push(each)

                }
                this.setState({ records: result, totalCount: res.data.count })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    // deleteRecord(record) {
    //     this.finalDelete(record)

    // }

    finalDelete(record) {
        axios
            .post("/api/geodata-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    exportToCSV = (from, to) => {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "desc" },
        }
        filterData.search = {
            ...(this.state.se_mobile !== '' && { se_mobile: this.state.se_mobile }),
            ...(this.state.mobile !== '' && { mobile: this.state.mobile }),
            ...(this.state.date !== '' && { date: this.state.date }),
        }
        axios
            .post("/api/geodata", filterData)
            .then(res => {
                toast.success('Downloading...Please Wait!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var headers = {
                    "mobile": "Mobile",
                    "name": "Name",
                    "se_name": "SE Name",
                    "se_mobile": "SE Mobile",
                    "se_id": "SE ID",
                    "FieldID": "FieldID",
                    "FieldArea": "FieldArea",
                    "date": "date",
                    "activeStatus": "Active Status"
                };
                // // add columns in sheet array
                // for (let column of this.props.columns) {
                //     headers[column.key] = '"' + column.text + '"';
                // }
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    record.date = record.date ? moment(record.date).add(5, 'hours').format('L') : ""
                    record.FieldArea = record.geodata ? record.geodata.FieldArea : ""
                    record.FieldID = record.geodata ? record.geodata.FieldID : ""
                    record.activeStatus= record.is_paused ? "Paused" : record.is_deleted ? "Deleted": "Active"
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject, headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }


    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    filter = (filterData) => {
        console.log("287 working", this.state.filterData)
        console.log("420 working", filterData)

        this.setState({ filterData }, () => this.getData())
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

   _onSelect = e => {
    console.log("385 working ", e.value);
    this.state.userType = e.value;
    this.setState({})
    console.log(this.state.userType)
   }

   _onFieldSatusSelect = e => {
    console.log("385 working ", e.value);
    this.state.fieldStatus = e.value;
    this.setState({})
    console.log(this.state.fieldStatus)
   }

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            se_mobile: undefined,
            mobile: undefined,
            date: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            }
        }, () => this.getData())
    }
    
    deleteRecord(record) {
        if(record.isDeleted == true){
            toast("Field already Deleted", {
                position: toast.POSITION.TOP_CENTER,
            })
        } else{
            this.setState({ currentRecord: record });

        }
    }


    pauseField(record) {
        if(record.isPaused != true && record.isDeleted != true){
            this.setState({ currentRecord: record });

    }}


    resumeField(record) {
        if(record.isPaused == true && record.isDeleted != true){
            this.setState({ currentRecord: record });
        }
    }

    render() {
        const { optionSelected } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <DeleteGeoDataDialog record={this.state.currentRecord} getData={this.getData} />
                    <ResumePauseGeoDataModal record={this.state.currentRecord} getData={this.getData} />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Farmer Geodata</h1>
                        
      <br></br>

                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>

                                <div className="form-group col-md-3">
                                        <label htmlFor="userType">User Type</label>
                                        <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select an option" />

                                    </div>
                                    
                                    <div className="form-group col-md-3">
                                        <label htmlFor="mobile">Farmer Mobile</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.mobile}
                                            id="mobile"
                                            type="text"
                                            className="form-control"
                                            readOnly={this.state.userType == "SE" ? true : false}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="se_mobile">SE Mobile</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.se_mobile}
                                            id="se_mobile"
                                            type="text"
                                            className="form-control"
                                            readOnly={this.state.userType == "Farmer" ? true : false}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="date">Date</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.date}
                                            id="date"
                                            type="date"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label htmlFor="fieldStatus">Field Status</label>
                                        <Dropdown options={fieldStatuses} onChange={this._onFieldSatusSelect} value={defaultStatus} placeholder="Select an option" />

                                    </div>


                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                exportToCSV={this.exportToCSV}
                                total_record={this.state.totalCount}
                                onChange={this.filter}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Geodata);
