import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import User from "./../components/pages/Users";
import News from "./../components/pages/News";
import Appdevicetoken from "./../components/pages/Appdevicetoken";
import CropProtection from "./../components/pages/CropProtection";
import SendPushnotification from "./../components/pages/SendPushnotification";
import DiseaseDetection from "./../components/pages/DiseaseDetection";
import DiseaseMaster from "./../components/pages/DiseaseMaster";
import Soiltesting from "./../components/pages/Soiltesting";
import Geodata from "./../components/pages/Geodata";
import Pop from "./../components/pages/Pop";
import PrivateRoute from "./../components/private-route/PrivateRoute";
import Dashboard from "./../components/pages/Dashboard";
import Sidebar from "./../components/partials/Sidebar";
import Setting from "../components/pages/Setting";
import farmonautArea from "../components/pages/FarmonautArea";
import ImageUpload from "../components/pages/ImageUpload";
import CropsData from "../components/pages/CropsData";
import NotificationHistory from "../components/pages/NotificationHistory";
import HomeCardsGrid from "../components/pages/HomeCards";


import PushNotification from "../components/pages/PushNotification";


class RouteData extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        let { roles } = this.props.auth.user;
        console.log(roles)
        roles = roles===undefined?[]:roles
        return (
            <>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                {roles.includes("All") && (
                    <>
                        
                        <PrivateRoute exact path="/users" component={User} />
                        <PrivateRoute exact path="/news" component={News} />
                        <PrivateRoute exact path="/Appdevicetoken" component={Appdevicetoken} />
                        <PrivateRoute exact path="/send-push" component={SendPushnotification} />
                        <PrivateRoute exact path="/crop-protection" component={CropProtection} />
                        <PrivateRoute exact path="/disease-detection" component={DiseaseDetection} />
                        <PrivateRoute exact path="/soil-testing" component={Soiltesting} />
                        <PrivateRoute exact path="/geo-data" component={Geodata} />
                        <PrivateRoute exact path="/pop" component={Pop} />
                        <PrivateRoute exact path="/disease-master" component={DiseaseMaster} />
                        <PrivateRoute exact path="/setting" component={Setting} />

                        <PrivateRoute exact path="/farmonaut-area" component={farmonautArea} />

                        <PrivateRoute exact path="/image-upload" component={ImageUpload} />

                        <PrivateRoute exact path="/crops-data" component={CropsData} />

                        <PrivateRoute exact path="/push_notification" component={PushNotification} />

                        <PrivateRoute exact path="/notification_history" component={NotificationHistory} />

                        <PrivateRoute exact path="/homecard_grids" component={HomeCardsGrid} />




                    </>
                )}
                {(roles.includes("UserV") || roles.includes("UserD")) && (
                    <>
                        <PrivateRoute exact path="/users" component={User} />
                    </>
                )}
                {(roles.includes("NewsV") || roles.includes("NewsD")) && (
                    <PrivateRoute exact path="/news" component={News} />
                )}
                {(roles.includes("AppDeviceTokenV") || roles.includes("AppDeviceTokenD")) && (
                    <PrivateRoute exact path="/Appdevicetoken" component={Appdevicetoken} />
                )}
                {(roles.includes("PushNotification")) && (
                    <PrivateRoute exact path="/send-push" component={SendPushnotification} />
                )}
                {(roles.includes("CropProtectionV") || roles.includes("CropProtectionD")) && (
                    <PrivateRoute exact path="/crop-protection" component={CropProtection} />
                )}
                {(roles.includes("DiseaseDetectionV") || roles.includes("DiseaseDetectionD")) && (
                    <PrivateRoute exact path="/disease-detection" component={DiseaseDetection} />
                )}
                {(roles.includes("SoilTestingV") || roles.includes("SoilTestingD")) && (
                    <PrivateRoute exact path="/soil-testing" component={Soiltesting} />
                )}
                {(roles.includes("GeodataV") || roles.includes("GeodataD")) && (
                    <PrivateRoute exact path="/geo-data" component={Geodata} />
                )}
                {(roles.includes("PopV") || roles.includes("PopD")) && (
                    <PrivateRoute exact path="/pop" component={Pop} />
                )}

               {(roles.includes("farmonautV") || roles.includes("farmonautE")) && (
                        <PrivateRoute exact path="/setting" component={Setting} />
                        )}

                    {(roles.includes("farmonautV") || roles.includes("farmonautE")) && (
                        <PrivateRoute exact path="/farmonaut-area" component={farmonautArea} />
                        )}
            </>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(RouteData);
