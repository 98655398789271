import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import SettingSideBar from "../partials/SettingSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from "moment"



var options = [
    'All',
  ];
const defaultOption = options[0];


class CropsData extends Component {
  

    constructor(props) {
        super(props);

        this.cropIdColumn= {
            key: "id",
            text: "ID",
            className: "mobile",
            align: "left",
            sortable: true,
        }

        this.cropNameColumn=  {
            key: "name",
            text: "Crop Name",
            className: "name",
            align: "left",
            sortable: true,
        }

        this.cropCategoryColumn = {
            key: "crop_category",
            text: "Crop Category",
            className: "crop_category",
            align: "left",
            sortable: true,
        }

    






        // this.options = [
        //     'one', 'two', 'three'
        //   ];
        this.columns = [
            this.cropIdColumn,
            this.cropNameColumn,
            this.cropCategoryColumn

    
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "CropsData",
            no_data_text: 'No CropsData found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            currentRecord: {
                id: '',
                devicetoken: '',
                customertype: '',
            },
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date_formatted", order: "desc" }
            },
            totalCount: 0,
            crop_name: '',
            category: "All",
            id: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.crop_name !== '' && { crop_name: this.state.crop_name }),
            ...(this.state.category !== 'All' && { category: this.state.category}),
            ...(this.state.id !== '' && { id: this.state.id }),
        
        }
        axios
            .post("/api/get-crops", filterData)
            .then(res => {
                if(options.includes('All') == false){
                    options= ['All']
                }
                let result = []
                for (let i = 0; i < res.data.data.length; i++) {
                    let each = res.data.data[i]
                    if(options.includes(each.name) == false){
                        console.log("144 working", options.includes(each.name))
                        options.push(each.name);
                    }
                    for(let j =0; j< each.child.length; j++){
                        let childEach = each.child[j];
                        childEach.crop_category = each.name;
                        if(this.state.crop_name !== ""){
                            if(childEach.name.toLowerCase().includes(this.state.crop_name.toLowerCase())){
                                result.push(childEach)
                            }
                        }else {
                            result.push(childEach)

                        }
                    }
                 

                }
                this.setState({ records: result, totalCount: res.data.count })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    // deleteRecord(record) {
    //     this.finalDelete(record)

    // }


    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    exportToCSV = (from, to) => {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "desc" },
        }
        filterData.search = {
            ...(this.state.crop_name !== '' && { crop_name: this.state.crop_name }),
            ...(this.state.category !== 'All' && { category: this.state.category}),
            ...(this.state.id !== '' && { id: this.state.id }),
        }
        axios
            .post("/api/get-crops", filterData)
            .then(res => {
                toast.success('Downloading...Please Wait!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var headers = {
                    "id": "ID",
                    "name": "Crop Name",
                    "crop_category": "Category Name",
                };
                // // add columns in sheet array
                // for (let column of this.props.columns) {
                //     headers[column.key] = '"' + column.text + '"';
                // }
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i = 0; i < res.data.data.length; i++) {
                    let each = res.data.data[i]
                    if(options.includes(each.name) == false){
                        options.push(each.name);
                    }
                    for(let j =0; j< each.child.length; j++){
                        let childEach = each.child[j];
                        childEach.crop_category = each.name;
                        if(this.state.crop_name !== ""){
                            if(childEach.name.toLowerCase().includes(this.state.crop_name.toLowerCase())){
                                records.push(childEach)
                            }
                        }else {
                            records.push(childEach)

                        }
                    }
                 

                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject, headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }


    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    filter = (filterData) => {
        console.log("287 working", this.state.filterData)
        console.log("420 working", filterData)

        this.setState({ filterData }, () => this.getData())
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

   _onSelect = e => {
    console.log("385 working ", e.value);
    this.state.category = e.value;
    this.setState({})
   }


    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            crop_name: '',
            category: undefined,
            id: undefined,
            filterData: {   
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            }
        }, () => this.getData())
    }
    
    deleteRecord(record) {
        if(record.isDeleted == true){
            toast("Field already Deleted", {
                position: toast.POSITION.TOP_CENTER,
            })
        } else{
            this.setState({ currentRecord: record });

        }
    }


    pauseField(record) {
        if(record.isPaused != true && record.isDeleted != true){
            this.setState({ currentRecord: record });

    }}


    resumeField(record) {
        if(record.isPaused == true && record.isDeleted != true){
            this.setState({ currentRecord: record });
        }
    }

    render() {
        const { optionSelected } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                <SettingSideBar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Crops</h1>
                             <br></br>
                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>

                                <div className="form-group col-md-3">
                                        <label htmlFor="userType">Category</label>
                                        <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select an option" />

                                    </div>
                                    
                                    <div className="form-group col-md-3">
                                        <label htmlFor="crop_name">Crop Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.crop_name}
                                            id="crop_name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                



                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                exportToCSV={this.exportToCSV}
                                total_record={this.state.totalCount}
                                onChange={this.filter}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(CropsData);
