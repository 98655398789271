import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";

class SettingSideBar extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        const { user } = this.props.auth;
        console.log(user)
        return (
            <div className="border-right h-100" id="sidebar-wrapper">
                <div className="list-group list-group-flush">                    <>
                            <Link to="/setting" className="list-group-item list-group-item-action">Farmonaut Months</Link>
                            <Link to="/farmonaut-area" className="list-group-item list-group-item-action">Farmonaut Area</Link>
                            <Link to="/image-upload" className="list-group-item list-group-item-action">Image Upload</Link>
                            <Link to="/crops-data" className="list-group-item list-group-item-action">Crops</Link>
                            <Link to="/homecard_grids" className="list-group-item list-group-item-action">Home Screen Cards</Link>



                            {/* <Link to="/news" className="list-group-item list-group-item-action">News</Link>
                            <Link to="/appdevicetoken" className="list-group-item list-group-item-action">Appdevicetoken</Link>
                            <Link to="/send-push" className="list-group-item list-group-item-action">Send Pushnotification</Link>
                            <Link to="/crop-protection" className="list-group-item list-group-item-action">Crop Protection</Link>
                            <Link to="/disease-detection" className="list-group-item list-group-item-action">Disease Detection</Link>
                            <Link to="/soil-testing" className="list-group-item list-group-item-action">Soil Testing</Link>
                            <Link to="/geo-data" className="list-group-item list-group-item-action">Farmer Geodata</Link>
                            <Link to="/pop" className="list-group-item list-group-item-action">Pop</Link>
                            <Link to="/disease-master" className="list-group-item list-group-item-action">Disease Master</Link> */}
                        </>

                    <button className="list-group-item list-group-item-action" onClick={this.onLogoutClick}>Logout <FontAwesomeIcon icon={faSignOutAlt} /></button>
                </div>
            </div>
        );
    }
}

SettingSideBar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(SettingSideBar);
