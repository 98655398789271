import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";

class Sidebar extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        const { user } = this.props.auth;
        console.log(user)
        return (
            <div className="border-right h-100" id="sidebar-wrapper">
                <div className="list-group list-group-flush">
                    <Link to="/dashboard" className="list-group-item list-group-item-action">Dashboard</Link>
                    {user.roles.includes("All") && (
                        <>
                            <Link to="/users" className="list-group-item list-group-item-action">Users</Link>
                            <Link to="/news" className="list-group-item list-group-item-action">News</Link>
                            <Link to="/appdevicetoken" className="list-group-item list-group-item-action">Appdevicetoken</Link>
                            <Link to="/send-push" className="list-group-item list-group-item-action">Send Pushnotification</Link>
                            <Link to="/crop-protection" className="list-group-item list-group-item-action">Crop Protection</Link>
                            <Link to="/disease-detection" className="list-group-item list-group-item-action">Disease Detection</Link>
                            <Link to="/soil-testing" className="list-group-item list-group-item-action">Soil Testing</Link>
                            <Link to="/geo-data" className="list-group-item list-group-item-action">Farmer Geodata</Link>
                            <Link to="/pop" className="list-group-item list-group-item-action">Pop</Link>
                            <Link to="/disease-master" className="list-group-item list-group-item-action">Disease Master</Link>
                            <Link to="/setting" className="list-group-item list-group-item-action">Setting</Link>

                            <Link to="/push_notification" className="list-group-item list-group-item-action">Send Notification</Link>

                            <Link to="/notification_history" className="list-group-item list-group-item-action">Notification History</Link>




                        </>
                    )}
                    {(user.roles.includes("UserV") || user.roles.includes("UserD")) && (
                        <>
                            <Link to="/users" className="list-group-item list-group-item-action">Users</Link>
                        </>
                    )}
                    {(user.roles.includes("NewsV") || user.roles.includes("NewsD")) && (
                        <>
                            <Link to="/news" className="list-group-item list-group-item-action">News</Link>
                        </>
                    )}
                    {(user.roles.includes("AppDeviceTokenV") || user.roles.includes("AppDeviceTokenD")) && (
                        <>
                            <Link to="/appdevicetoken" className="list-group-item list-group-item-action">Appdevicetoken</Link>
                        </>
                    )}
                    {(user.roles.includes("PushNotification")) && (
                        <>
                            <Link to="/send-push" className="list-group-item list-group-item-action">Send Pushnotification</Link>
                        </>
                    )}
                    {(user.roles.includes("CropProtectionV") || user.roles.includes("CropProtectionD")) && (
                        <>
                            <Link to="/crop-protection" className="list-group-item list-group-item-action">Crop Protection</Link>
                        </>
                    )}
                    {(user.roles.includes("DiseaseDetectionV") || user.roles.includes("DiseaseDetectionD")) && (
                        <>
                            <Link to="/disease-detection" className="list-group-item list-group-item-action">Disease Detection</Link>
                        </>
                    )}
                    {(user.roles.includes("SoilTestingV") || user.roles.includes("SoilTestingD")) && (
                        <>
                            <Link to="/soil-testing" className="list-group-item list-group-item-action">Soil Testing</Link>
                        </>
                    )}
                    {(user.roles.includes("GeodataV") || user.roles.includes("GeodataD")) && (
                        <>
                            <Link to="/geo-data" className="list-group-item list-group-item-action">Farmer Geodata</Link>
                        </>
                    )}
                    {(user.roles.includes("PopV") || user.roles.includes("PopD")) && (
                        <>
                            <Link to="/pop" className="list-group-item list-group-item-action">Pop</Link>
                        </>
                    )}

                    {(user.roles.includes("DiseaseMasterV") || user.roles.includes("DiseaseMasterD")) && (
                        <>
                            <Link to="/disease-master" className="list-group-item list-group-item-action">Disease Master</Link>
                        </>
                    )}

                   {(user.roles.includes("farmonautV") || user.roles.includes("farmonautE")) && (
                        <>
                          <Link to="/setting" className="list-group-item list-group-item-action">Setting</Link>

                        </>
                    )}

                    <button className="list-group-item list-group-item-action" onClick={this.onLogoutClick}>Logout <FontAwesomeIcon icon={faSignOutAlt} /></button>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);
