import React from 'react'
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
class SoiltestingUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            soil_id: "",
            sample_image_url: "",
            ph: "",
            nitrogen: "",
            phosphorus: "",
            potassium: "",
            ec: "",
            oc: "",
            sulphur: "",
            calcium: "",
            magnesium: "",
            zinc: "",
            iron: "",
            boron: "",
            copper: "",
            manganese: "",
            sodium: "",
            recommended: "",
            editorState: "",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (!_.isEqual(this.props.record, nextProps.record)) {
            this.setState({
                soil_id: "",
                sample_image_url: "",
                ph: "",
                nitrogen: "",
                phosphorus: "",
                potassium: "",
                ec: "",
                oc: "",
                sulphur: "",
                calcium: "",
                magnesium: "",
                zinc: "",
                iron: "",
                boron: "",
                copper: "",
                manganese: "",
                sodium: "",
                recommended: "",
                ...nextProps.record,
            });

            const html = nextProps.record.recommended;
            if (html !== undefined) {
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    this.setState({
                        editorState,
                    })
                }
            }
        }
        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message !== undefined) {
        //     $('#add-news-modal').modal('hide');
        //     toast(nextProps.auth.user.data.message, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSoilUpdate = e => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.ph === '') {
            errors.ph = "This field is required"
        } else {
            delete errors.ph
        }


        if (isEmpty(errors)) {
            let formData = {
                soil_id: this.state.soil_id,
                farmer_mobile: this.state.farmer_mobile,
                sample_crop_id: this.state.sample_crop_id,
                ph: this.state.ph,
                nitrogen: this.state.nitrogen,
                phosphorus: this.state.phosphorus,
                potassium: this.state.potassium,
                ec: this.state.ec,
                oc: this.state.oc,
                sulphur: this.state.sulphur,
                calcium: this.state.calcium,
                magnesium: this.state.magnesium,
                zinc: this.state.zinc,
                iron: this.state.iron,
                boron: this.state.boron,
                copper: this.state.copper,
                manganese: this.state.manganese,
                sodium: this.state.sodium,
                recommended: this.state.recommended,

            }
            if (this.state.editorState != "" && stateToHTML(this.state.editorState.getCurrentContent()) !== undefined)
                formData.recommended = stateToHTML(this.state.editorState.getCurrentContent());
            axios.post('/api/soiltesting-update', formData)
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        ph: "",
                        phosphorus: "",
                        potassium: "",
                        nitrogen: "",
                        ec: "",
                        oc: "",
                        sulphur: "",
                        calcium: "",
                        magnesium: "",
                        zinc: "",
                        iron: "",
                        boron: "",
                        copper: "",
                        manganese: "",
                        sodium: "",
                        recommended: "",
                    })
                    $('#soil-update-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        let { errors } = this.state;
        errors = errors === undefined ? {} : errors
        return (
            <div>
                <div className="modal" id="soil-update-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Results</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onSoilUpdate} id="soil-update" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Soil ID</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.soil_id}
                                                id="soil_id"
                                                type="text"
                                                error={errors.soil_id}
                                                readOnly
                                                className={classnames("form-control", {
                                                    invalid: errors.soil_id
                                                })} />
                                            <span className="text-danger">{errors.soil_id}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ph">PH</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="ph"
                                                type="text"
                                                error={errors.ph}
                                                value={this.state.ph}
                                                className={classnames("form-control", {
                                                    invalid: errors.ph
                                                })} />
                                            <span className="text-danger">{errors.ph}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="nitrogen">Nitrogen</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="nitrogen"
                                                type="text"
                                                error={errors.nitrogen}
                                                value={this.state.nitrogen}
                                                className={classnames("form-control", {
                                                    invalid: errors.nitrogen
                                                })} />
                                            <span className="text-danger">{errors.nitrogen}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phosphorus">Phosphorus</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="phosphorus"
                                                type="text"
                                                error={errors.phosphorus}
                                                value={this.state.phosphorus}
                                                className={classnames("form-control", {
                                                    invalid: errors.phosphorus
                                                })} />
                                            <span className="text-danger">{errors.phosphorus}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="potassium">Potassium</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="potassium"
                                                type="text"
                                                error={errors.potassium}
                                                value={this.state.potassium}
                                                className={classnames("form-control", {
                                                    invalid: errors.potassium
                                                })} />
                                            <span className="text-danger">{errors.potassium}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ec">EC</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="ec"
                                                type="text"
                                                error={errors.ec}
                                                value={this.state.ec}
                                                className={classnames("form-control", {
                                                    invalid: errors.ec
                                                })} />
                                            <span className="text-danger">{errors.ec}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="oc">OC</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="oc"
                                                type="text"
                                                error={errors.oc}
                                                value={this.state.oc}
                                                className={classnames("form-control", {
                                                    invalid: errors.oc
                                                })} />
                                            <span className="text-danger">{errors.oc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sulphur">Sulphur</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sulphur"
                                                type="text"
                                                error={errors.sulphur}
                                                value={this.state.sulphur}
                                                className={classnames("form-control", {
                                                    invalid: errors.sulphur
                                                })} />
                                            <span className="text-danger">{errors.sulphur}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="calcium">Calcium</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="calcium"
                                                type="text"
                                                error={errors.calcium}
                                                value={this.state.calcium}
                                                className={classnames("form-control", {
                                                    invalid: errors.calcium
                                                })} />
                                            <span className="text-danger">{errors.calcium}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="magnesium">Magnesium</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="magnesium"
                                                type="text"
                                                error={errors.magnesium}
                                                value={this.state.magnesium}
                                                className={classnames("form-control", {
                                                    invalid: errors.magnesium
                                                })} />
                                            <span className="text-danger">{errors.magnesium}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="zinc">Zinc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="zinc"
                                                type="text"
                                                error={errors.zinc}
                                                value={this.state.zinc}
                                                className={classnames("form-control", {
                                                    invalid: errors.zinc
                                                })} />
                                            <span className="text-danger">{errors.zinc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="iron">Iron</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="iron"
                                                type="text"
                                                error={errors.iron}
                                                value={this.state.iron}
                                                className={classnames("form-control", {
                                                    invalid: errors.iron
                                                })} />
                                            <span className="text-danger">{errors.iron}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="boron">Boron</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="boron"
                                                type="text"
                                                error={errors.boron}
                                                value={this.state.boron}
                                                className={classnames("form-control", {
                                                    invalid: errors.boron
                                                })} />
                                            <span className="text-danger">{errors.boron}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="copper">Copper</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="copper"
                                                type="text"
                                                error={errors.copper}
                                                value={this.state.copper}
                                                className={classnames("form-control", {
                                                    invalid: errors.copper
                                                })} />
                                            <span className="text-danger">{errors.copper}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="manganese">Manganese</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="manganese"
                                                type="text"
                                                error={errors.manganese}
                                                value={this.state.manganese}
                                                className={classnames("form-control", {
                                                    invalid: errors.manganese
                                                })} />
                                            <span className="text-danger">{errors.manganese}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sodium">Sodium</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="sodium"
                                                type="text"
                                                error={errors.sodium}
                                                value={this.state.sodium}
                                                className={classnames("form-control", {
                                                    invalid: errors.sodium
                                                })} />
                                            <span className="text-danger">{errors.sodium}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="sodium">Recommendation</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor
                                                value={this.state.recommended}
                                                editorState={this.state.editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    type="submit"
                                    onClick={this.onSoilUpdate}
                                    className="btn btn-primary">
                                    Update Result
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(SoiltestingUpdateModal));
