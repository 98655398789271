import React, {Component} from 'react'
import loading from './loading.gif'

class Loader extends Component{
    render(){
        return <div>
            <img src= {loading} width={25} height={25} alt = "loading"/>
        </div>
    }
}

export default Loader
