import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopAddModal from "../partials/PopAddModal";
import PopUpdateModal from "../partials/PopUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
// import Excel from 'exceljs';
import Papa from "papaparse";
import { object } from "prop-types";



class Pop extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "title",
                text: "Title",
                className: "title",
                align: "left",
                sortable: true,
            },
            {
                key: "stage",
                text: "Stage",
                className: "stage",
                align: "left",
                sortable: true,
            },
            {
                key: "category",
                text: "Category",
                className: "category",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "date_formatted",
                text: "Date",
                className: "date_formatted",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-pop-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {(this.props.auth.user.roles.includes("PopD") || this.props.auth.user.roles.includes("All")) &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Pop",
            no_data_text: 'No pop found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            selectedFile : undefined,
            currentRecord: {
                id: '',
                name: '',
                email: '',
            },
            stage: undefined,
            category: undefined,
            title: undefined,
            filterData: {
                page_number: 1,
                page_size: 500,
                sort_order: { column: "_id", order: "desc" }
            },
        };


        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

     httpGetAsync(theUrl, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() { 
            console.log(xmlHttp.readyState)
            console.log(xmlHttp.status)
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
                callback(xmlHttp.response);
                else callback(null);
        }
        xmlHttp.open("GET", theUrl, true); // true for asynchronous 
        xmlHttp.send(null);
            }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.stage !== '' && { stage: this.state.stage }),
            ...(this.state.category !== '' && { category: this.state.category }),
            ...(this.state.title !== '' && { title: this.state.title }),
        }
        axios
            .post("/api/pop", filterData)
            .then(res => {
                let result = []
                
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.date_formatted = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                        each.status = each.status == 'Active' ? "Active" : "Inactive"
                        each.is_sowing_stage = each.is_sowing_stage == true ? "1" : "0";
                        each.category = each.category != '' ? parseInt(each.category) : ""
                        each.stage = each.stage != '' ? parseInt(each.stage) : ""
                        each.sowing_date = each.sowing_date != '' ? each.sowing_date : ""
                        each.crop_advisory_main_stage = each.crop_advisory_main_stage != '' ? each.crop_advisory_main_stage : ""
                        each.crop_advisory_main_sub_stage = each.crop_advisory_main_sub_stage != '' ? each.crop_advisory_main_sub_stage : ""
                        each.sub_stage_days = each.sub_stage_days != '' ? each.sub_stage_days : ""

                        each.days_after_sowing = each.days_after_sowing != '' ? each.days_after_sowing : ""
                        each.category_name = each.category_name != '' ? each.category_name : ""
                        each.category_image = each.category_image != '' ? each.category_image : ""
                        each.recommended_products = each.recommended_products != '' ? each.recommended_products : ""
                        each.title = each.title != '' ? each.title : ""
                        each.main_stage_title = each.main_stage_title != '' ? each.main_stage_title : ""
                        each.description = each.description != '' ? each.description : ""
                        each.video_title = each.video_title != '' ? each.video_title : ""

                        each.video_url = each.video_url != '' ? each.video_url : ""
                        each.video_image = each.video_image != '' ? each.video_image : ""
                        each.possible_problems = each.possible_problems != '' ? each.possible_problems : ""
                        each.possible_problems_gallery = each.possible_problems_gallery != '' ? each.possible_problems_gallery : ""
                        each.best_practices = each.best_practices != '' ? each.best_practices : ""
                        each.best_practices_gallery = each.best_practices_gallery != '' ? each.best_practices_gallery : ""
                        each.time_to_grow = each.time_to_grow != '' ? each.time_to_grow : ""
                        each.title_hi = each.title_hi != '' ? each.title_hi : ""
                        each.main_stage_title_hi = each.main_stage_title_hi != '' ? each.main_stage_title_hi : ""
                        each.description_hi = each.description_hi != '' ? each.description_hi : ""
                        each.video_title_hi = each.video_title_hi != '' ? each.video_title_hi : ""
                        each.video_url_hi = each.video_url_hi != '' ? each.video_url_hi : ""
                        each.video_image_hi = each.video_image_hi != '' ? each.video_image_hi : ""
                        each.possible_roblems_hi = each.possible_roblems_hi != '' ? each.possible_roblems_hi : ""


                        each.possible_roblems_gallery_hi = each.possible_roblems_gallery_hi != '' ? each.possible_roblems_gallery_hi : ""
                        each.best_practices_hi = each.best_practices_hi != '' ? each.best_practices_hi : ""
                        each.best_practices_gallery_hi = each.best_practices_gallery_hi != '' ? each.best_practices_gallery_hi : ""
                        each.time_to_grow_hi = each.time_to_grow_hi != '' ? each.time_to_grow_hi : ""
                        each.main_stage_title_te = each.main_stage_title_te != '' ? each.main_stage_title_te : ""
                        each.title_te = each.title_te != '' ? each.title_te : ""
                        each.description_te = each.description_te != '' ? each.description_te : ""
                        each.video_title_te = each.video_title_te != '' ? each.video_title_te : ""
                        each.video_url_te = each.video_url_te != '' ? each.video_url_te : ""
                        each.video_image_te = each.video_image_te != '' ? each.video_image_te : ""
                        each.possible_roblems_te = each.possible_roblems_te != '' ? each.possible_roblems_te : ""
                        each.possible_roblems_gallery_te = each.possible_roblems_gallery_te != '' ? each.possible_roblems_gallery_te : ""
                        each.best_practices_te = each.best_practices_te != '' ? each.best_practices_te : ""
                        each.best_practices_gallery_te = each.best_practices_gallery_te != '' ? each.best_practices_gallery_te : ""

                        each.time_to_grow_te = each.time_to_grow_te != '' ? each.time_to_grow_te : ""
                        each.main_stage_title_mr = each.main_stage_title_mr != '' ? each.main_stage_title_mr : ""
                        each.title_mr = each.title_mr != '' ? each.title_mr : ""
                        each.description_mr = each.description_mr != '' ? each.description_mr : ""
                        each.video_title_mr = each.video_title_mr != '' ? each.video_title_mr : ""
                        each.video_url_mr = each.video_url_mr != '' ? each.video_url_mr : ""
                        each.video_image_mr = each.video_image_mr != '' ? each.video_image_mr : ""
                        each.possible_roblems_mr = each.possible_roblems_mr != '' ? each.possible_roblems_mr : ""
                        each.possible_roblems_gallery_mr = each.possible_roblems_gallery_mr != '' ? each.possible_roblems_gallery_mr : ""
                        each.best_practices_mr = each.best_practices_mr != '' ? each.best_practices_mr : ""
                        each.best_practices_gallery_mr = each.best_practices_gallery_mr != '' ? each.best_practices_gallery_mr : ""
                        each.time_to_grow_mr = each.time_to_grow_mr != '' ? each.time_to_grow_mr : ""
                        each.main_stage_title_pa = each.main_stage_title_pa != '' ? each.main_stage_title_pa : ""
                        each.title_pa = each.title_pa != '' ? each.title_pa : ""
                        each.description_pa = each.description_pa != '' ? each.description_pa : ""

                        each.video_title_pa = each.video_title_pa != '' ? each.video_title_pa : ""
                        each.video_url_pa = each.video_url_pa != '' ? each.video_url_pa : ""
                        each.video_image_pa = each.video_image_pa != '' ? each.video_image_pa : ""
                        each.possible_roblems_pa = each.possible_roblems_pa != '' ? each.possible_roblems_pa : ""
                        each.possible_roblems_gallery_pa = each.possible_roblems_gallery_pa != '' ? each.possible_roblems_gallery_pa : ""
                        each.best_practices_pa = each.best_practices_pa != '' ? each.best_practices_pa : ""
                        each.best_practices_gallery_pa = each.best_practices_gallery_pa != '' ? each.best_practices_gallery_pa : ""
                        each.time_to_grow_pa = each.time_to_grow_pa != '' ? each.time_to_grow_pa : ""

                        result.push(each)
                    }

                }
                this.setState({ records: result })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/pop-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    
    onFilePick = event =>  {
        this.setState({[event.target.id]: event.target.files[0] });
       if(event.target.value != null){
        // const wb = new Excel.Workbook();
        const reader = new FileReader();
//    reader.readAsArrayBuffer(event.target.files[0]);
reader.readAsText(event.target.files[0]);
   reader.onload = () => {
    const buffer = reader.result;
    const csv = Papa.parse(buffer, { header: true });
    const parsedData = csv.data;
    const columns = Object.values(parsedData[5]);
    // console.log(columns[4]);
    for(let i=0;i<parsedData.length;i++){
        const data = parsedData[i];
        // console.log(data.values);

        axios.post('/api/pop-add', {
                        category:data['category_id'],
                        stage: data['stage'],
                        stage_image_url: data['stage_image'],
                        sowing_date:data['sowing_date'],
                        crop_advisory_main_stage:data['crop_advisory'],
                        crop_advisory_main_sub_stage: data['crop_advisory_child'],
                        sub_stage_days: data['sub_stage_days'],
                        days_after_sowing: data['total_days'],
                        recommended_products:data['recommended_products'],
                        
                        main_stage_title: data['main_stage_title'],
                        title: data['title'],
                        description:data['description'],
                        video_title: data['video_title'],
                        video_url: data['video_link'],
                        video_image:"",
                        possible_problems:data['possible_problems'],
                        possible_problems_gallery: data['possible_problems_gallery'],
                        best_practices: data['best_practices'],
                        best_practices_gallery:data['best_practices_gallery'],
                       
                        main_stage_title_hi: data['main_stage_title_hi'],
                        title_hi: data['title_hi'],
                        description_hi:data['description_hi'],
                        video_title_hi: data['video_title_hi'],
                        video_url_hi: data['video_link_hi'],
                        video_image_hi:"",
                        possible_problems_hi:data['possible_problems_hi'],
                        possible_problems_gallery_hi: data['possible_problems_hi_gallery'],
                        best_practices_hi: data['best_practices_hi'],
                        best_practices_gallery_hi:data['best_practices_hi_gallery'],
      
      
                        main_stage_title_te: data['main_stage_title_te'],
                        title_te: data['title_te'],
                        description_te: data['description_te'],
                        video_title_te: data['video_title_te'],
                        video_url_te: data['video_link_te'],
                        video_image_te:"",
                        possible_problems_te: data['possible_problems_te'],
                        possible_problems_gallery_te: data['possible_problems_te_gallery'],
                        best_practices_te: data['best_practices_te'],
                        best_practices_gallery_te: data['best_practices_te_gallery'],
      
                        // main_stage_title_te: row.values[14],
                        // title_te: this.state.title_te,
                        // description_te:this.state.description_te,
                        // video_title_te: this.state.video_title_te,
                        // video_url_te: this.state.video_url_te,
                        // video_image_te:this.state.video_image_te,
                        // possible_problems_te:this.state.possible_problems_te,
                        // best_practices_te: this.state.best_practices_te,
                        // possible_problems_gallery_te: this.state.possible_problems_gallery_te,
                        // best_practices_gallery_te:this.state.best_practices_gallery_te,
      
      
                        main_stage_title_mr: data['main_stage_title_mr'],
                        title_mr: data['title_mr'],
                        description_mr:data['description_mr'],
                        video_title_mr: data['video_title_mr'],
                        video_url_mr: data['video_link_mr'],
                        video_image_mr:"",
                        possible_problems_mr: data['possible_problems_mr'],
                        possible_problems_gallery_mr: data['possible_problems_mr_gallery'],
                        best_practices_mr: data['best_practices_mr'],
                        best_practices_gallery_mr: data['best_practices_mr_gallery'],
      
      
                        main_stage_title_pa: data['main_stage_title_pa'],
                        title_pa: data['title_pa'],
                        description_pa: data['description_pa'],
                        video_title_pa: data['video_title_pa'],
                        video_url_pa: data['video_link_pa'],
                        video_image_pa:"",
                        possible_problems_pa: data['possible_problems_pa'],
                        possible_problems_gallery_pa: data['possible_problems_pa_gallery'],
                        best_practices_pa: data['best_practices_pa'],
                        best_practices_gallery_pa: data['best_practices_pa_gallery'],
      
      
                        category_name:data['category_name'],
                        category_image:data['category_name'],
                        time_to_grow: data['time_to_grow'],
                        time_to_grow_hi: data['time_to_grow_hi'],
                        time_to_grow_te: data['time_to_grow_te'],
                        time_to_grow_mr: data['time_to_grow_mr'],
                        time_to_grow_pa: data['time_to_grow_pa'],
                        status: data['status']  == 1 ? "Active" : "Inactive",
                        is_sowing_stage: data['is_sowing_stage'] == 1 ? true : false, 
                            },
                             {}).then(res => {
                                // if (res.status === 200) {
                                //     this.getData();
                                //     toast(res.data.message, {
                                //         position: toast.POSITION.TOP_CENTER,
                                //     })
                                // }
                            })
                            .catch();
    }
    toast("Data Updated Successfully", {
        position: toast.POSITION.TOP_CENTER,
    })
    // console.log(isDataCorrect)
    // wb.xlsx.load(buffer).then((workbook) => {
    //     console.log(workbook, 'workbook instance');
    //     workbook.eachSheet((sheet, id) => {
    //         sheet.eachRow((row, rowIndex) => {
    //             if(rowIndex != 1){
    //                 console.log(row.values[9], rowIndex);
    //                 // axios.post('/api/pop-add', {
    //                 //     category:row.values[2],
    //                 //     stage: row.values[4],
    //                 //     stage_image_url: JSON.stringify(row.values[5]['hyperlink'], null, 2),
    //                 //     sowing_date:row.values[6],
    //                 //     crop_advisory_main_stage:row.values[8],
    //                 //     crop_advisory_main_sub_stage: row.values[9],
    //                 //     sub_stage_days: row.values[10],
    //                 //     days_after_sowing: row.values[7],
    //                 //     recommended_products:row.values[42],
                        
    //                 //     main_stage_title: row.values[11],
    //                 //     title: row.values[17],
    //                 //     description:row.values[22],
    //                 //     video_title: row.values[43],
    //                 //     video_url: row.values[48],
    //                 //     video_image:"",
    //                 //     possible_problems:row.values[32],
    //                 //     possible_problems_gallery: row.values[53],
    //                 //     best_practices: row.values[37],
    //                 //     best_practices_gallery:row.values[58],
                       
    //                 //     main_stage_title_hi: row.values[12],
    //                 //     title_hi: row.values[18],
    //                 //     description_hi:row.values[23],
    //                 //     video_title_hi: row.values[44],
    //                 //     video_url_hi: row.values[49],
    //                 //     video_image_hi:"",
    //                 //     possible_problems_hi:row.values[33],
    //                 //     best_practices_hi: row.values[38],
    //                 //     possible_problems_gallery_hi: row.values[54],
    //                 //     best_practices_gallery_hi:row.values[59],
      
      
    //                 //     main_stage_title_te: row.values[13],
    //                 //     title_te: row.values[19],
    //                 //     description_te:row.values[24],
    //                 //     video_title_te: row.values[45],
    //                 //     video_url_te: row.values[50],
    //                 //     video_image_te:"",
    //                 //     possible_problems_te:row.values[34],
    //                 //     best_practices_te: row.values[39],
    //                 //     possible_problems_gallery_te: row.values[55],
    //                 //     best_practices_gallery_te:row.values[60],
      
    //                 //     // main_stage_title_te: row.values[14],
    //                 //     // title_te: this.state.title_te,
    //                 //     // description_te:this.state.description_te,
    //                 //     // video_title_te: this.state.video_title_te,
    //                 //     // video_url_te: this.state.video_url_te,
    //                 //     // video_image_te:this.state.video_image_te,
    //                 //     // possible_problems_te:this.state.possible_problems_te,
    //                 //     // best_practices_te: this.state.best_practices_te,
    //                 //     // possible_problems_gallery_te: this.state.possible_problems_gallery_te,
    //                 //     // best_practices_gallery_te:this.state.best_practices_gallery_te,
      
      
    //                 //     main_stage_title_mr: row.values[14],
    //                 //     title_mr: row.values[20],
    //                 //     description_mr:row.values[25],
    //                 //     video_title_mr: row.values[46],
    //                 //     video_url_mr: row.values[51],
    //                 //     video_image_mr:"",
    //                 //     possible_problems_mr:row.values[35],
    //                 //     best_practices_mr: row.values[40],
    //                 //     possible_problems_gallery_mr: row.values[56],
    //                 //     best_practices_gallery_mr:row.values[61],
      
      
    //                 //     main_stage_title_pa: row.values[15],
    //                 //     title_pa: row.values[21],
    //                 //     description_pa:row.values[26],
    //                 //     video_title_pa: row.values[47],
    //                 //     video_url_pa: row.values[52],
    //                 //     video_image_pa:"",
    //                 //     possible_problems_pa:row.values[36],
    //                 //     best_practices_pa: row.values[41],
    //                 //     possible_problems_gallery_pa: row.values[57],
    //                 //     best_practices_gallery_pa:row.values[62],
      
      
    //                 //     category_name:"",
    //                 //     category_image:"",
    //                 //     time_to_grow:row.values[27],
    //                 //     time_to_grow_hi:row.values[28],
    //                 //     time_to_grow_te:row.values[29],
    //                 //     time_to_grow_mr:row.values[30],
    //                 //     time_to_grow_pa:row.values[31],
    //                 //     status:row.values[3]  == 1 ? "Active" : "Inactive",
    //                 //     is_sowing_stage: row.values[16] == 1 ? true : false, 
    //                 //         }, {})
    //             }
    //         });
    //     });
    // });

    }
event.target.value = null;
this.setState();
    }
}

    onFileRemove(){
        this.setState({ selectedFile: null });
    }

    resetSearch = () => {
        this.setState({
            stage: undefined,
            category: undefined,
            title: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            }
        }, () => this.getData())
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <PopAddModal getData={this.getData} />
                    <PopUpdateModal record={this.state.currentRecord} getData={this.getData} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-pop-modal"><FontAwesomeIcon icon={faPlus} /> Add Pop</button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" 
                                    type = "file" data-toggle="modal"   
                                    id="image" onSubmit={this.onFilePick} data-target=""><FontAwesomeIcon icon={faPlus} /> Upload Pop Sheet  

                               <input
                                                onChange={this.onFilePick}
                                                id="selectedFile"
                                                type="file"
                                                accept=".csv,"

                                               
                             />
                            </button>
                         

                            <h1 className="mt-2 text-primary">Pop List</h1>
                            <br></br>
                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.title}
                                            id="title"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="category">Category</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.category}
                                            id="category"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="stage">Stage</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.stage}
                                            id="stage"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Pop);
