import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NewsAddModal from "../partials/NewsAddModal";
import NewsUpdateModal from "../partials/NewsUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
class News extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "title",
                text: "Title",
                className: "title",
                align: "left",
                sortable: true,
            },
            {
                key: "description",
                text: "Description",
                className: "description",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "date_formatted",
                text: "Date",
                className: "date_formatted",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-news-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {(this.props.auth.user.roles.includes("NewsD") || this.props.auth.user.roles.includes("All")) &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }

                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "News",
            no_data_text: 'No News found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
            },
            records: [],
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date", order: "desc" }
            },
            title: undefined,

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.title !== '' && { title: this.state.title }),
        }
        axios
            .post("/api/news", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.date_formatted = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                        each.status = each.status == 'Active' ? "Active" : "Inactive"
                        each.title = each.title != '' ? each.title : ""
                        each.description = each.description != '' ? each.description : ""
                        each.image = each.image != '' ? each.image : ""
                        each.title_hi = each.title_hi != '' ? each.title_hi : ""
                        each.description_hi = each.description_hi != '' ? each.description_hi : ""
                        each.image_hi = each.image_hi != '' ? each.image_hi : ""
                        each.title_te = each.title_te != '' ? each.title_te : ""
                        each.description_te = each.description_te != '' ? each.description_te : ""
                        each.image_te = each.image_te != '' ? each.image_te : ""
                        each.title_mr = each.title_mr != '' ? each.title_mr : ""
                        each.description_mr = each.description_mr != '' ? each.description_mr : ""
                        each.image_mr = each.image_mr != '' ? each.image_mr : ""
                        each.title_pa = each.title_pa != '' ? each.title_pa : ""
                        each.description_pa = each.description_pa != '' ? each.description_pa : ""
                        each.image_pa = each.image_pa != '' ? each.image_pa : ""
                        each.video_url = each.video_url != '' ? each.video_url : ""
                        each.video_url_hi = each.video_url_hi != '' ? each.video_url_hi : ""
                        each.video_url_te = each.video_url_te != '' ? each.video_url_te : ""
                        each.video_url_mr = each.video_url_mr != '' ? each.video_url_mr : ""
                        each.video_url_pa = each.video_url_pa != '' ? each.video_url_pa : ""

                        result.push(each)
                    }

                }
                this.setState({ records: result })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/news-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            title: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date", order: "desc" }
            }
        }, () => this.getData())
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <NewsAddModal getData={this.getData} />
                    <NewsUpdateModal record={this.state.currentRecord} getData={this.getData} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-news-modal"><FontAwesomeIcon icon={faPlus} /> Add News</button>
                            <h1 className="mt-2 text-primary">News List</h1>
                            <br></br>
                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.title}
                                            id="title"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(News);
