import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CropProtectionAddModal from "../partials/CropProtectionAddModal";
import CropProtectionUpdateModal from "../partials/CropProtectionUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
class CropProtection extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "ID",
                className: "_id",
                align: "left",
                sortable: true,
            },
            {
                key: "heading",
                text: "Heading",
                className: "heading",
                align: "left",
                sortable: true,
            },
            {
                key: "sub_heading",
                text: "Sub Heading",
                className: "sub_heading",
                align: "left",
                sortable: true,
            },
            {
                key: "crop",
                text: "Crop",
                className: "crop",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
            },
           
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-crop-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {(this.props.auth.user.roles.includes("CropProtectionD") || this.props.auth.user.roles.includes("All")) &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "crop",
            no_data_text: 'No Record found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
            },
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date_formatted", order: "desc" }
            },
            heading: undefined,
            crop: undefined,
            records: []
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.heading !== '' && { heading: this.state.heading }),
            ...(this.state.crop !== '' && { crop: this.state.crop }),
            ...(this.state.sub_heading !== '' && { sub_heading: this.state.sub_heading }),
        }
        axios
            .post("/api/crop-protection", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.date_formatted = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                        each.status = each.status == 'Active' ? "Active": "Inactive"
                        each.category_id = each.category_id != ''? each.category_id: ""
                        each.heading = each.heading != ''? each.heading: ""
                        each.sub_heading = each.sub_heading != ''? each.sub_heading: ""
                        each.video_url = each.video_url != ''? each.video_url: ""
                        each.image = each.image != ''? each.image: ""
                        each.crop_description_heading = each.crop_description_heading != ''? each.crop_description_heading: ""
                        each.crop_description_detail = each.crop_description_detail != ''? each.crop_description_detail: ""
                        each.symptoms_heading = each.symptoms_heading != ''? each.symptoms_heading: ""
                        each.symptoms_detail = each.symptoms_detail != ''? each.symptoms_detail: ""
                        each.preventive_measure_heading = each.preventive_measure_heading != ''? each.preventive_measure_heading: ""
                        each.preventive_measure_detail = each.preventive_measure_detail != ''? each.preventive_measure_detail: ""
                        each.control_measure_heading = each.control_measure_heading != ''? each.control_measure_heading: ""
                        each.control_measure_detail = each.control_measure_detail != ''? each.control_measure_detail: ""
                        each.recommended_products = each.recommended_products != ''? each.recommended_products: ""
                        each.heading_hi = each.heading_hi != ''? each.heading_hi: ""
                        each.sub_heading_hi = each.sub_heading_hi != ''? each.sub_heading_hi: ""
                        each.video_url_hi = each.video_url_hi != ''? each.video_url_hi: ""
                        each.image_hi = each.image_hi != ''? each.image_hi: ""
                        each.preventive_measure_detail_hi = each.preventive_measure_detail_hi != ''? each.preventive_measure_detail_hi: ""
                        each.crop_description_heading_hi = each.crop_description_heading_hi != ''? each.crop_description_heading_hi: ""
                        each.crop_description_detail_hi = each.crop_description_detail_hi != ''? each.crop_description_detail_hi: ""
                        each.symptoms_heading_hi = each.symptoms_heading_hi != ''? each.symptoms_heading_hi: ""
                        each.symptoms_detail_hi = each.symptoms_detail_hi != ''? each.symptoms_detail_hi: ""
                        each.preventive_measure_heading_hi = each.preventive_measure_heading_hi != ''? each.preventive_measure_heading_hi: ""
                        each.control_measure_heading_hi = each.control_measure_heading_hi != ''? each.control_measure_heading_hi: ""
                        each.control_measure_detail_hi = each.control_measure_detail_hi != ''? each.control_measure_detail_hi: ""
                        each.recommended_products_hi = each.recommended_products_hi != ''? each.recommended_products_hi: ""
                        each.heading_te = each.heading_te != ''? each.heading_te: ""
                        each.sub_heading_te = each.sub_heading_te != ''? each.sub_heading_te: ""
                        each.video_url_te = each.video_url_te != ''? each.video_url_te: ""
                        each.image_te = each.image_te != ''? each.image_te: ""
                        each.preventive_measure_detail_te = each.preventive_measure_detail_te != ''? each.preventive_measure_detail_te: ""
                        each.crop_description_heading_te = each.crop_description_heading_te != ''? each.crop_description_heading_te: ""
                        each.crop_description_detail_te = each.crop_description_detail_te != ''? each.crop_description_detail_te: ""
                        each.symptoms_heading_te = each.symptoms_heading_te != ''? each.symptoms_heading_te: ""
                        each.symptoms_detail_te = each.symptoms_detail_te != ''? each.symptoms_detail_te: ""
                        each.preventive_measure_heading_te = each.preventive_measure_heading_te != ''? each.preventive_measure_heading_te: ""
                        each.control_measure_heading_te = each.control_measure_heading_te != ''? each.control_measure_heading_te: ""
                        each.control_measure_detail_te = each.control_measure_detail_te != ''? each.control_measure_detail_te: ""
                        each.recommended_products_te = each.recommended_products_te != ''? each.recommended_products_te: ""
                        each.heading_mr = each.heading_mr != ''? each.heading_mr: ""
                        each.sub_heading_mr = each.sub_heading_mr != ''? each.sub_heading_mr: ""
                        each.video_url_mr = each.video_url_mr != ''? each.video_url_mr: ""
                        each.image_mr = each.image_mr != ''? each.image_mr: ""
                        each.preventive_measure_detail_mr = each.preventive_measure_detail_mr != ''? each.preventive_measure_detail_mr: ""
                        each.crop_description_heading_mr = each.crop_description_heading_mr != ''? each.crop_description_heading_mr: ""
                        each.crop_description_detail_mr = each.crop_description_detail_mr != ''? each.crop_description_detail_mr: ""
                        each.symptoms_heading_mr = each.symptoms_heading_mr != ''? each.symptoms_heading_mr: ""
                        each.symptoms_detail_mr = each.symptoms_detail_mr != ''? each.symptoms_detail_mr: ""
                        each.preventive_measure_heading_mr = each.preventive_measure_heading_mr != ''? each.preventive_measure_heading_mr: ""
                        each.control_measure_heading_mr = each.control_measure_heading_mr != ''? each.control_measure_heading_mr: ""
                        each.control_measure_detail_mr = each.control_measure_detail_mr != ''? each.control_measure_detail_mr: ""
                        each.recommended_products_mr = each.recommended_products_mr != ''? each.recommended_products_mr: ""
                        each.heading_pa = each.heading_pa != ''? each.heading_pa: ""
                        each.sub_heading_pa = each.sub_heading_pa != ''? each.sub_heading_pa: ""
                        each.video_url_pa = each.video_url_pa != ''? each.video_url_pa: ""
                        each.image_pa = each.image_pa != ''? each.image_pa: ""
                        each.preventive_measure_detail_pa = each.preventive_measure_detail_pa != ''? each.preventive_measure_detail_pa: ""
                        each.crop_description_heading_pa = each.crop_description_heading_pa != ''? each.crop_description_heading_pa: ""
                        each.crop_description_detail_pa = each.crop_description_detail_pa != ''? each.crop_description_detail_pa: ""
                        each.symptoms_heading_pa = each.symptoms_heading_pa != ''? each.symptoms_heading_pa: ""
                        each.symptoms_detail_pa = each.symptoms_detail_pa != ''? each.symptoms_detail_pa: ""
                        each.preventive_measure_heading_pa = each.preventive_measure_heading_pa != ''? each.preventive_measure_heading_pa: ""
                        each.control_measure_heading_pa = each.control_measure_heading_pa != ''? each.control_measure_heading_pa: ""
                        each.control_measure_detail_pa = each.control_measure_detail_pa != ''? each.control_measure_detail_pa: ""
                        each.recommended_products_pa = each.recommended_products_pa != ''? each.recommended_products_pa: ""

                        result.push(each)
                    }

                }
                this.setState({ records: result })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/crop-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            heading: undefined,
            crop: undefined,
            sub_heading: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            }
        }, () => this.getData())
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <CropProtectionAddModal getData={this.getData} />
                    <CropProtectionUpdateModal record={this.state.currentRecord}  getData={this.getData}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-crop-modal"><FontAwesomeIcon icon={faPlus} /> Add Crop Protection</button>
                            <h1 className="mt-2 text-primary">Crop Protection List</h1>
                            <br></br>
                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="heading">Heading</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.heading}
                                            id="heading"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="sub_heading">Sub Heading</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.sub_heading}
                                            id="sub_heading"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="crop">Crop</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.crop}
                                            id="crop"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(CropProtection);
