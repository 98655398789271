import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import SettingSideBar from "../partials/SettingSidebar";
import classnames from "classnames";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";



class Settings extends Component {


    constructor(props) {
        super();
        this.state = {
            value: "",
            _id: "",
            errors: {}
        }
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {
        this.getData()

    };

    getData(){
        axios
        .post("/api/get-farmonaut-month").then(res=>{
            this.setState({value : res.data.data.value, _id: res.data.data.id});
        
        }).catch(e=> {
            console.log(e);
        });
    }


    onSubmit = e => {
        e.preventDefault();
        if(this.state._id === ""){
            axios
            .post("/api/farmonaut-month-add",{ _value:this.state.value}).then(res=>{
                console.log(res.data.message);
                if(res.status === 200){
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            
            }).catch(e=> {
                console.log(e);
            });
        }else{
            axios
            .post("/api/farmonaut-month-update",{ _value:this.state.value , id: this.state._id}).then(res=>{
                console.log(res.data.message);
                if(res.status === 200){
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            
            }).catch(e=> {
                console.log(e);
            });
        }
      
    };

    onChange = e => {
        if((e.target.value <= 6 && e.target.value > 0) || e.target.value === ""){
            this.setState({ [e.target.id]: e.target.value });
        }
    };
    

    render() {
        //const { user } = this.props.auth;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <SettingSideBar />
                    <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-1">
                            <h2 className="text-center text-primary mt-3">Farmonaut Month</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                                <label htmlFor="value">Enter Value (Enter digit between 1 to 6)</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.value}
                                    // error={errors.value}
                                    id="value"
                                    type="number"
                                    min="1"
                                    max="6"
                                    className={classnames("form-control", {
                                        // invalid: errors.value
                                    })
                                }
                                />
                                <span className="text-danger">{}</span>
                                {(this.props.auth.user.roles.includes("farmonautE") || this.props.auth.user.roles.includes("All")) &&
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5">
                                        Update
                                    </button>
                                </p>}
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Settings);
