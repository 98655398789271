import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AppdevicetokenUpdateModal from "../partials/AppdevicetokenUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
class Appdevicetoken extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "mobile",
                text: "Mobile",
                className: "mobile",
                align: "left",
                sortable: true,
            },
            {
                key: "location",
                text: "Location",
                className: "location",
                align: "left",
                sortable: true,
            },
            {
                key: "customertype",
                text: "Customer Type",
                className: "customertype",
                align: "left",
                sortable: true,
            },
           
            {
                key: "language",
                text: "Language",
                className: "language",
                align: "left",
                sortable: true
            },
            {
                key: "date_formatted",
                text: "Date",
                className: "date_formatted",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-appdevicetoken-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-eye"></i>
                            </button>
                            {(this.props.auth.user.roles.includes("AppDeviceTokenD") || this.props.auth.user.roles.includes("All")) &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Appdevicetoken",
            no_data_text: 'No Appdevicetoken found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            currentRecord: {
                id: '',
                devicetoken: '',
                customertype: '',
            },
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date_formatted", order: "desc" }
            },
            totalCount: 0
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        let filterData = this.state.filterData
        axios
            .post("/api/appdevicetoken", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.date_formatted = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                        each.mobile = each.mobile != ''? each.mobile: ""
                        each.location = each.location != ''? each.location: ""
                        each.language = each.language != ''? each.language: ""
                        each.app_register = each.app_register != ''? each.app_register: ""
                        each.otp_status = each.otp_status != ''? each.otp_status: ""
                        each.otp_submit = each.otp_submit != ''? each.otp_submit: ""
                        each.customerdetailsapi = each.customerdetailsapi != ''? each.customerdetailsapi: ""
                        each.device_details = each.device_details != ''? each.device_details: ""
                        result.push(each)
                    }

                }
                this.setState({ records: result, totalCount: res.data.count })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/appdevicetoken-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    exportToCSV = (from, to) => {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "desc" }
        }
        axios
            .post("/api/appdevicetoken", filterData)
            .then(res => {
                toast.success('Dowloading...Please Wait!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var headers = {
                    "devicetoken": "devicetoken",
                    "location": "location",
                    "language": "language",
                    "customertype": "customertype",
                    "mobile": "mobile",
                    "app_register": "app_register",
                    "otp_status": "otp_status",
                    "otp_submit": "otp_submit",
                    "customerdetailsapi": "customerdetailsapi",
                    "device_details": "device_details",
                    "date": "date"
                };
                // // add columns in sheet array
                // for (let column of this.props.columns) {
                //     headers[column.key] = '"' + column.text + '"';
                // }
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject,headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <AppdevicetokenUpdateModal record={this.state.currentRecord}  getData={this.getData}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Appdevicetoken List</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                exportToCSV={this.exportToCSV}
                                total_record={this.state.totalCount}
                                onChange={this.filter}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Appdevicetoken);
