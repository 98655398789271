import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SoiltestingUpdateModal from "../partials/SoiltestingUpdateModal";
import { toast, ToastContainer } from "react-toastify";

import moment from "moment"
class Soiltesting extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "soil_id",
                text: "Soil Id",
                className: "soil_id",
                align: "left",
                sortable: true,
            },
            {
                key: "se_name",
                text: "SE Name",
                className: "se_name",
                align: "left",
                sortable: true,
            },
            {
                key: "se_mobile",
                text: "SE Mobile",
                className: "se_mobile",
                align: "left",
                sortable: true
            },
            {
                key: "farmer_name",
                text: "Farmer Name",
                className: "farmer_name",
                align: "left",
                sortable: true,
            },

            {
                key: "farmer_mobile",
                text: "Farmer Mobile",
                className: "farmer_mobile",
                align: "left",
                sortable: true
            },

            {
                key: "sample_image_url",
                text: "Image Url",
                className: "sample_image_url",
                align: "left",
                sortable: true,
                view: true
            },
            {
                key: "sample_crop_id",
                text: "Crop Id",
                className: "sample_crop_id",
                align: "left",
                sortable: true
            },
            {
                key: "sample_crop_name",
                text: "Crop Name",
                className: "sample_crop_name",
                align: "left",
                sortable: true
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true
            },
            {
                key: "ph",
                text: "PH",
                className: "ph",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#soil-update-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {(this.props.auth.user.roles.includes("SoilTestingD") || this.props.auth.user.roles.includes("All")) &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "DiseaseDetection",
            no_data_text: 'No disease detection found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            currentRecord: {
                id: '',
                devicetoken: '',
                customertype: '',
            },
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date_formatted", order: "desc" }
            },
            totalCount: 0,
            mobile: undefined,
            se_mobile: undefined,
            se_name: undefined,
            farmer_name: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    // componentWillReceiveProps(nextProps) {
    //     this.getData()
    // }

    getData() {
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.se_mobile !== '' && { se_mobile: this.state.se_mobile }),
            ...(this.state.mobile !== '' && { farmer_mobile: this.state.mobile }),
            ...(this.state.farmer_name !== '' && { farmer_name: this.state.farmer_name }),
            ...(this.state.se_name !== '' && { se_name: this.state.se_name }),
            ...(this.state.date !== '' && { date: this.state.date }),
        }
        axios
            .post("/api/soiltesting", filterData)
            .then(res => {
                let result = []
                let soilIds = []
                for (let i = 0; i < res.data.records.soilData.length; i++) {
                    let each = res.data.records.soilData[i]
                    each.date = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                    soilIds.push(each.soil_id)
                    result.push(each)
                }
                axios
                    .post("/api/cropdata", {soilIds: soilIds})
                    .then(resC => {
                        let result = []
                        for (let i = 0; i < res.data.records.soilData.length; i++) {
                            let each = res.data.records.soilData[i]
                            let cropSoilData = resC.data.records.find(eachC => eachC.soil_id === each.soil_id)
                            each.finalid = each._id
                            each = {...each, ...cropSoilData}
                            result.push(each)
                        }
                        this.setState({ records: result, totalCount: res.data.count })
                    })
                    .catch()
                this.setState({ records: result, totalCount: res.data.count })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    deleteRecord(record) {
        this.finalDelete(record)

    }

    finalDelete(record) {
        axios
            .post("/api/soil-delete", { _id: record.finalid })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();

    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    exportToCSV = (from, to) => {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "desc" }
        }
        filterData.search = {
            ...(this.state.se_mobile !== '' && { se_mobile: this.state.se_mobile }),
            ...(this.state.mobile !== '' && { farmer_mobile: this.state.mobile }),
            ...(this.state.farmer_name !== '' && { farmer_name: this.state.farmer_name }),
            ...(this.state.se_name !== '' && { se_name: this.state.se_name }),
            ...(this.state.date !== '' && { date: this.state.date }),
        }
        axios
            .post("/api/soiltesting", filterData)
            .then(res => {
                toast.success('Dowloading...Please Wait!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var headers = {
                    "farmer_mobile": "farmer Mobile",
                    "farmer_name": "Farmer Name",
                    "soil_id": "Soil Id",
                    "se_name": "SE Name",
                    "se_mobile": "SE Mobile",
                    "sample_image_url": "Image",
                    "sample_crop_id": "Crop Id",
                    "sample_crop_name": "Crop Name",
                    "date": "date"
                };
                // // add columns in sheet array
                // for (let column of this.props.columns) {
                //     headers[column.key] = '"' + column.text + '"';
                // }
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records.soilData) {
                    let record = res.data.records.soilData[i]
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject, headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            se_mobile: undefined,
            mobile: undefined,
            se_name: undefined,
            farmer_name: undefined,
            date: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            }
        }, () => this.getData())
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <SoiltestingUpdateModal record={this.state.currentRecord} getData={this.getData} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Soil Testing List</h1>
                            <br></br>
                            <form noValidate onSubmit={this.onSearch}>
                                <div className="form-row" style={{ width: "1000px" }}>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="mobile">Farmer Mobile</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.mobile}
                                            id="mobile"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="farmer_name">Farmer Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.farmer_name}
                                            id="farmer_name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="se_mobile">SE Mobile</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.se_mobile}
                                            id="se_mobile"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="se_name">SE Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.se_name}
                                            id="se_name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="date">Date</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.date}
                                            id="date"
                                            type="date"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Filter</button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </form>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                exportToCSV={this.exportToCSV}
                                total_record={this.state.totalCount}
                                onChange={this.filter}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Soiltesting);
