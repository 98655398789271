import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import SettingSideBar from "../partials/SettingSidebar";
import classnames from "classnames";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";



class ImageUpload extends Component {


    constructor(props) {
        super();
        this.state = {
            imageUrl: "",
            _id: "",
            errors: {}
        }
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {
        this.getData()

    };

    getData(){
    
    }


    onSubmit = e => {
        e.preventDefault();
        if(this.state.minValue === "" || this.state.maxValue === ""){
            toast("Fields are Mandatory", {
                position: toast.POSITION.TOP_CENTER,
            })
        }else if(this.state._id === ""){
            axios
            .post("/api/farmonaut-area-add",{ minValue:this.state.minValue, maxValue: this.state.maxValue}).then(res=>{
                console.log(res.data.message);
                if(res.status === 200){
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            
            }).catch(e=> {
                console.log(e);
            });
        }else{
            axios
            .post("/api/farmonaut-area-update",{ minValue:this.state.minValue, maxValue: this.state.maxValue , id: this.state._id}).then(res=>{
                console.log(res.data.message);
                if(res.status === 200){
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            
            }).catch(e=> {
                console.log(e);
            });
        }
      
    };


    
    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
        const formData = new FormData();
        formData.append("image", event.target.files[0]);
        axios
            .post("/api/get-image-url",formData, {}).then((res) => {
                if(res.status === 200){
                    this.setState({
                        imageUrl : res.data.url 
                     });
                }
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
               
            });
       
    };

    render() {
        const { errors } = this.state;

        //const { user } = this.props.auth;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <SettingSideBar />
                    <div className="container">
                <div className="row mt-5">
                        <div className="card-body p-1">
                            <h2 className="text-center text-primary mt-3">Image Upload</h2>
                            <div className="col-md-9"style={{ paddingLeft: "60.250px" }}>
                                            <input
                                                onChange={this.onImageChange}
                                                id="image"
                                                type="file"
                                                error={errors.image}
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })} />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>

                                        
                                        <div className="col-md-9"style={{ paddingLeft: "60.250px" }}>    
                                <h7>{this.state.imageUrl} </h7>
                                </div>
                                <span className="text-danger">{}</span>
                              
                        </div>
                </div>
                <ToastContainer />
            </div>
                </div>
            </div>
        );
    }
}

ImageUpload.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(ImageUpload);
