import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Multiselect from 'multiselect-react-dropdown';

class PopAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            status:"Active",
            is_sowing_stage : "0",
            category: "",
            stage: "",
            stage_image_url: "",
            sowing_date: "",
            crop_advisory_main_stage: "",
            crop_advisory_main_sub_stage: "",
            sub_stage_days: "",
            days_after_sowing: "",
            recommended_products: "",
            title: "",
            main_stage_title: "",
            description: "",
            video_title: "",
            video_url: "",
            video_image: "",
            possible_problems: "",
            possible_problems_gallery: "",
            best_practices: "",
            best_practices_gallery: "",
            title_hi: "",
            main_stage_title_hi: "",
            description_hi: "",
            video_title_hi: "",
            video_url_hi: "",
            video_image_hi: "",
            possible_problems_hi: "",
            possible_problems_gallery_hi: "",
            best_practices_hi: "",
            best_practices_gallery_hi: "",
            title_te: "",
            main_stage_title_te: "",
            description_te: "",
            video_title_te: "",
            video_url_te: "",
            video_image_te: "",
            possible_problems_te: "",
            possible_problems_gallery_te: "",
            best_practices_te: "",
            best_practices_gallery_te: "",
            title_mr: "",
            main_stage_title_mr: "",
            description_mr: "",
            video_title_mr: "",
            video_url_mr: "",
            video_image_mr: "",
            possible_problems_mr: "",
            possible_problems_gallery_mr: "",
            best_practices_mr: "",
            best_practices_gallery_mr: "",
            title_pa: "",
            main_stage_title_pa: "",
            description_pa: "",
            video_title_pa: "",
            video_url_pa: "",
            video_image_pa: "",
            possible_problems_pa: "",
            possible_problems_gallery_pa: "",
            best_practices_pa: "",
            best_practices_gallery_pa: "",
            time_to_grow: "",
            time_to_grow_hi: "",
            time_to_grow_te: "",
            time_to_grow_mr: "",
            time_to_grow_pa: "",
            category_name: "",
            category_image: "",
            errors: {},
            options: [{ label: 'English', value: 1 }, { label: 'Hindi', value: 2 }]
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-pop-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onPopAdd = e => {
        e.preventDefault();

        let { errors } = this.state
        if (this.state.category === '') {
            errors.category = "This field is required"
        } else {
            delete errors.category
        }
        if (this.state.stage === '') {
            errors.stage = "This field is required"
        } else {
            delete errors.stage
        }

       if (this.state.sowing_date === '') {
            errors.sowing_date = "This field is required"
        } else {
            delete errors.sowing_date
        }


        if (this.state.crop_advisory_main_stage === '') {
            errors.crop_advisory_main_stage = "This field is required"
        } else {
            delete errors.crop_advisory_main_stage
        }

        if (this.state.crop_advisory_main_sub_stage === '') {
            errors.crop_advisory_main_sub_stage = "This field is required"
        } else {
            delete errors.crop_advisory_main_sub_stage
        }

        if (this.state.days_after_sowing === '') {
            errors.days_after_sowing = "This field is required"
        } else {
            delete errors.days_after_sowing
        }
        if (this.state.title === '') {
            errors.title = "This field is required"
        } else {
            delete errors.title
        }
        if (this.state.main_stage_title === '') {
            errors.main_stage_title = "This field is required"
        } else {
            delete errors.main_stage_title
        }
        if (this.state.stage_image_url === '') {
            errors.stage_image_url = "This field is required"
        } else {
            delete errors.stage_image_url
        }
        if (this.state.sowing_date === '') {
            errors.sowing_date = "This field is required"
        } else {
            delete errors.sowing_date
        }

        if (isEmpty(errors)) {
            const formData = new FormData();
            axios.post('/api/pop-add', {
                  category:this.state.category,
                  stage: this.state.stage,
                  stage_image_url: this.state.stage_image_url,
                  sowing_date:this.state.sowing_date,
                  crop_advisory_main_stage:this.state.crop_advisory_main_stage,
                  crop_advisory_main_sub_stage: this.state.crop_advisory_main_sub_stage,
                  sub_stage_days: this.state.sub_stage_days,
                  days_after_sowing: this.state.days_after_sowing,
                  recommended_products:this.state.recommended_products,
                  
                  main_stage_title: this.state.main_stage_title,
                  title: this.state.title,
                  description:this.state.description,
                  video_title: this.state.video_title,
                  video_url: this.state.video_url,
                  video_image:this.state.video_image,
                  possible_problems:this.state.possible_problems,
                  possible_problems_gallery: this.state.possible_problems_gallery,
                  best_practices: this.state.best_practices,
                  best_practices_gallery:this.state.best_practices_gallery,
                 
                  main_stage_title_hi: this.state.main_stage_title_hi,
                  title_hi: this.state.title_hi,
                  description_hi:this.state.description_hi,
                  video_title_hi: this.state.video_title_hi,
                  video_url_hi: this.state.video_url_hi,
                  video_image_hi:this.state.video_image_hi,
                  possible_problems_hi:this.state.possible_problems_hi,
                  best_practices_hi: this.state.best_practices_hi,
                  possible_problems_gallery_hi: this.state.possible_problems_gallery_hi,
                  best_practices_gallery_hi:this.state.best_practices_gallery_hi,


                  main_stage_title_te: this.state.main_stage_title_te,
                  title_te: this.state.title_te,
                  description_te:this.state.description_te,
                  video_title_te: this.state.video_title_te,
                  video_url_te: this.state.video_url_te,
                  video_image_te:this.state.video_image_te,
                  possible_problems_te:this.state.possible_problems_te,
                  best_practices_te: this.state.best_practices_te,
                  possible_problems_gallery_te: this.state.possible_problems_gallery_te,
                  best_practices_gallery_te:this.state.best_practices_gallery_te,

                  main_stage_title_te: this.state.main_stage_title_te,
                  title_te: this.state.title_te,
                  description_te:this.state.description_te,
                  video_title_te: this.state.video_title_te,
                  video_url_te: this.state.video_url_te,
                  video_image_te:this.state.video_image_te,
                  possible_problems_te:this.state.possible_problems_te,
                  best_practices_te: this.state.best_practices_te,
                  possible_problems_gallery_te: this.state.possible_problems_gallery_te,
                  best_practices_gallery_te:this.state.best_practices_gallery_te,


                  main_stage_title_mr: this.state.main_stage_title_mr,
                  title_mr: this.state.title_mr,
                  description_mr:this.state.description_mr,
                  video_title_mr: this.state.video_title_mr,
                  video_url_mr: this.state.video_url_mr,
                  video_image_mr:this.state.video_image_mr,
                  possible_problems_mr:this.state.possible_problems_mr,
                  best_practices_mr: this.state.best_practices_mr,
                  possible_problems_gallery_mr: this.state.possible_problems_gallery_mr,
                  best_practices_gallery_mr:this.state.best_practices_gallery_mr,


                  main_stage_title_pa: this.state.main_stage_title_pa,
                  title_pa: this.state.title_pa,
                  description_pa:this.state.description_pa,
                  video_title_pa: this.state.video_title_pa,
                  video_url_pa: this.state.video_url_pa,
                  video_image_pa:this.state.video_image_pa,
                  possible_problems_pa:this.state.possible_problems_pa,
                  best_practices_pa: this.state.best_practices_pa,
                  possible_problems_gallery_pa: this.state.possible_problems_gallery_pa,
                  best_practices_gallery_pa:this.state.best_practices_gallery_pa,


                  category_name:this.state.category_name,
                  category_image:this.state.category_image,
                  time_to_grow:this.state.time_to_grow,
                  time_to_grow_hi:this.state.time_to_grow_hi,
                  time_to_grow_te:this.state.time_to_grow_te,
                  time_to_grow_mr:this.state.time_to_grow_mr,
                  time_to_grow_pa:this.state.time_to_grow_pa,
                  status:this.state.status,
                  is_sowing_stage: this.state.is_sowing_stage === "1" ? true : false,
                      }, {})
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        status:"Active",
                        category: "",
                        stage: "",
                        stage_image_url: "",
                        sowing_date: "",
                        crop_advisory_main_stage: "",
                        crop_advisory_main_sub_stage: "",
                        sub_stage_days: "",
                        days_after_sowing: "",
                        recommended_products: "",
                        main_stage_title: "",
                        title: "",
                        description: "",
                        video_title: "",
                        video_url: "",
                        video_image: "",
                        possible_problems: "",
                        possible_problems_gallery: "",
                        best_practices: "",
                        best_practices_gallery: "",
                        main_stage_title_hi: "",
                        title_hi: "",
                        description_hi: "",
                        video_title_hi: "",
                        video_url_hi: "",
                        video_image_hi: "",
                        possible_problems_hi: "",
                        possible_problems_gallery_hi: "",
                        best_practices_hi: "",
                        best_practices_gallery_hi: "",
                        main_stage_title_te: "",
                        title_te: "",
                        description_te: "",
                        video_title_te: "",
                        video_url_te: "",
                        video_image_te: "",
                        possible_problems_te: "",
                        possible_problems_gallery_te: "",
                        best_practices_te: "",
                        best_practices_gallery_te: "",
                        main_stage_title_mr : "",
                        title_mr: "",
                        description_mr: "",
                        video_title_mr: "",
                        video_url_mr: "",
                        video_image_mr: "",
                        possible_problems_mr: "",
                        possible_problems_gallery_mr: "",
                        best_practices_mr: "",
                        best_practices_gallery_mr: "",
                        main_stage_title_pa: "",
                        title_pa: "",
                        description_pa: "",
                        video_title_pa: "",
                        video_url_pa: "",
                        video_image_pa: "",
                        possible_problems_pa: "",
                        possible_problems_gallery_pa: "",
                        best_practices_pa: "",
                        best_practices_gallery_pa: "",
                        time_to_grow: "",
                        time_to_grow_hi: "",
                        time_to_grow_te: "",
                        time_to_grow_mr: "",
                        time_to_grow_pa: "",
                        category_name: "",
                        category_image: "",
                        is_sowing_stage: "0",
                    })
                    $('#add-pop-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-pop-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Pop</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPopAdd} id="add-pop" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Category</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category}
                                                id="category"
                                                type="number"
                                                error={errors.category}
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })} />
                                            <span className="text-danger">{errors.category}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Category Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category_name}
                                                id="category_name"
                                                type="text"
                                                error={errors.category_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.category_name
                                                })} />
                                            <span className="text-danger">{errors.category_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Category Image URL</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category_image}
                                                id="category_image"
                                                type="text"
                                                error={errors.category_image}
                                                className={classnames("form-control", {
                                                    invalid: errors.category_image
                                                })} />
                                            <span className="text-danger">{errors.category_image}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Current Stage is Sowing Stage?</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.is_sowing_stage}
                                                id="is_sowing_stage"
                                                type="text"
                                                error={errors.is_sowing_stage}
                                                className={classnames("form-control", {
                                                    invalid: errors.is_sowing_stage
                                                })} />
                                            <span className="text-danger">{errors.is_sowing_stage}</span>
                                        </div>
                                    </div>
                                  
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Stage</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="stage"
                                                type="number"
                                                error={errors.stage}
                                                className={classnames("form-control", {
                                                    invalid: errors.stage
                                                })} />
                                            <span className="text-danger">{errors.stage}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Stage Image Url</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="stage_image_url"
                                                type="text"
                                                error={errors.stage_image_url}
                                                className={classnames("form-control", {
                                                    invalid: errors.stage_image_url
                                                })} />
                                            <span className="text-danger">{errors.stage_image_url}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Sowing Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.sowing_date}
                                                error={errors.sowing_date}
                                                id="sowing_date"
                                                type="date"
                                                className={classnames("form-control", {
                                                    invalid: errors.sowing_date
                                                })}
                                            />
                                            <span className="text-danger">{errors.sowing_date}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Crop Advisory Main Stage (i.e. 0-3)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.crop_advisory_main_stage}
                                                id="crop_advisory_main_stage"
                                                type="text"
                                                error={errors.crop_advisory_main_stage}
                                                className={classnames("form-control", {
                                                    invalid: errors.crop_advisory_main_stage
                                                })} />
                                            <span className="text-danger">{errors.crop_advisory_main_stage}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Crop Advisory Main Sub Stage(i.e. 0-2)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="crop_advisory_main_sub_stage"
                                                type="text"
                                                error={errors.crop_advisory_main_sub_stage}
                                                className={classnames("form-control", {
                                                    invalid: errors.crop_advisory_main_sub_stage
                                                })} />
                                            <span className="text-danger">{errors.crop_advisory_main_sub_stage}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Sub Stage Days(i.e. 0-2)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.sub_stage_days}
                                                id="sub_stage_days"
                                                type="text"
                                                error={errors.sub_stage_days}
                                                className={classnames("form-control", {
                                                    invalid: errors.sub_stage_days
                                                })} />
                                            <span className="text-danger">{errors.sub_stage_days}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Total Days</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="days_after_sowing"
                                                type="number"
                                                error={errors.days_after_sowing}
                                                className={classnames("form-control", {
                                                    invalid: errors.days_after_sowing
                                                })} />
                                            <span className="text-danger">{errors.days_after_sowing}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Recommended Products(comma seprated sku)</label>
                                        </div>
                                        <div className="col-md-9">
                                                <textarea  className={classnames("form-control", {
                                                    invalid: errors.recommended_products
                                                })} id="recommended_products"  
                                             value={this.state.recommended_products} onChange={this.onChange} />
                                          
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Main Stage Title(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.main_stage_title}
                                                id="main_stage_title"
                                                type="text"
                                                error={errors.main_stage_title}
                                                className={classnames("form-control", {
                                                    invalid: errors.main_stage_title
                                                })} />
                                            <span className="text-danger">{errors.main_stage_title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Description(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea  className={classnames("form-control", {
                                                    invalid: errors.description
                                                })} id="description"  
                                             value={this.state.description} onChange={this.onChange} />
                            
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title">Video Title(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_title"
                                                type="text"
                                                error={errors.video_title}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_title
                                                })} />
                                            <span className="text-danger">{errors.video_title}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title">Video Url(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url"
                                                type="text"
                                                error={errors.video_url}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url
                                                })} />
                                            <span className="text-danger">{errors.video_url}</span>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_image">Video Image(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.video_image}
                                                id="video_image"
                                                type="text"
                                                error={errors.video_image}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_image
                                                })} />
                                            <span className="text-danger">{errors.video_image}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Possible Problems(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                                  
                                                <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems
                                                })} id="possible_problems" 

                                                 value={this.state.possible_problems} onChange={this.onChange} />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Possible Problems Gallery(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                                <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_gallery
                                                })} id="possible_problems_gallery" 

                                                 value={this.state.possible_problems_gallery} onChange={this.onChange} />
                                
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="best_practices">Best Practices(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.best_practices}
                                                error={errors.best_practices}
                                                id="best_practices"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.best_practices
                                                })}
                                            />
                                            <span className="text-danger">{errors.best_practices}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Best Practices Gallery(English)</label>
                                        </div>
                                        <div className="col-md-9">

                                         <textarea  className={classnames("form-control", {
                                                    invalid: errors.best_practices_gallery
                                                })} id="best_practices_gallery" 

                                                 value={this.state.best_practices_gallery} onChange={this.onChange} />

                                        
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Time To Grow(English)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time_to_grow}
                                                id="time_to_grow"
                                                type="text"
                                                error={errors.time_to_grow}
                                                className={classnames("form-control", {
                                                    invalid: errors.time_to_grow
                                                })} />
                                            <span className="text-danger">{errors.time_to_grow}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Main Stage Title(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.main_stage_title_hi}
                                                id="main_stage_title_hi"
                                                type="text"
                                                error={errors.main_stage_title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.main_stage_title_hi
                                                })} />
                                            <span className="text-danger">{errors.main_stage_title_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_hi}
                                                id="title_hi"
                                                type="text"
                                                error={errors.title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_hi
                                                })} />
                                            <span className="text-danger">{errors.title_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_hi">Description(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea  className={classnames("form-control", {
                                                    invalid: errors.description_hi
                                                })} id="description_hi"  
                                             value={this.state.description_hi} onChange={this.onChange} />
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title_hi">Video Title(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_title_hi"
                                                type="text"
                                                error={errors.video_title_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_title_hi
                                                })} />
                                            <span className="text-danger">{errors.video_title_hi}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_hi">Video Url(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_hi"
                                                type="text"
                                                error={errors.video_url_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_hi
                                                })} />
                                            <span className="text-danger">{errors.video_url_hi}</span>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_image_hi">Video Image(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.video_image_hi}
                                                id="video_image_hi"
                                                type="text"
                                                error={errors.video_image_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_image_hi
                                                })} />
                                            <span className="text-danger">{errors.video_image_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_hi">Possible Problems(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                          <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_hi
                                                })} id="possible_problems_hi" 

                                                 value={this.state.possible_problems_hi} onChange={this.onChange} />
                                   
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_gallery_hi">Possible Problems Gallery(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">

                                          <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_gallery_hi
                                                })} id="possible_problems_gallery_hi" 

                                                 value={this.state.possible_problems_gallery_hi} onChange={this.onChange} />


                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="best_practices_hi">Best Practices(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.best_practices_hi}
                                                error={errors.best_practices_hi}
                                                id="best_practices_hi"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.best_practices_hi
                                                })}
                                            />
                                            <span className="text-danger">{errors.best_practices_hi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Best Practices Gallery(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                           <textarea  className={classnames("form-control", {
                                                    invalid: errors.best_practices_gallery_hi
                                                })} id="best_practices_gallery_hi" 

                                                 value={this.state.best_practices_gallery_hi} onChange={this.onChange} />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Time To Grow(Hindi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time_to_grow_hi}
                                                id="time_to_grow_hi"
                                                type="text"
                                                error={errors.time_to_grow_hi}
                                                className={classnames("form-control", {
                                                    invalid: errors.time_to_grow_hi
                                                })} />
                                            <span className="text-danger">{errors.time_to_grow_hi}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Main Stage Title(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.main_stage_title_te}
                                                id="main_stage_title_te"
                                                type="text"
                                                error={errors.main_stage_title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.main_stage_title_te
                                                })} />
                                            <span className="text-danger">{errors.main_stage_title_te}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_te}
                                                id="title_te"
                                                type="text"
                                                error={errors.title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_te
                                                })} />
                                            <span className="text-danger">{errors.title_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_te">Description(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.description_te
                                                })} id="description_te"  
                                             value={this.state.description_te} onChange={this.onChange} />
                                          
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title_te">Video Title(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_title_te"
                                                type="text"
                                                error={errors.video_title_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_title_te
                                                })} />
                                            <span className="text-danger">{errors.video_title_te}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_te">Video Url(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_te"
                                                type="text"
                                                error={errors.video_url_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_te
                                                })} />
                                            <span className="text-danger">{errors.video_url_te}</span>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_image_te">Video Image(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.video_image_te}
                                                id="video_image_te"
                                                type="text"
                                                error={errors.video_image_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_image_te
                                                })} />
                                            <span className="text-danger">{errors.video_image_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_te">Possible Problems(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                         <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_te
                                                })} id="possible_problems_te" 

                                                 value={this.state.possible_problems_te} onChange={this.onChange} />
                                   
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_gallery_te">Possible Problems Gallery(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                             <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_gallery_te
                                                })} id="possible_problems_gallery_te" 

                                                 value={this.state.possible_problems_gallery_te} onChange={this.onChange} />
                                         
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="best_practices_te">Best Practices(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.best_practices_te}
                                                error={errors.best_practices_te}
                                                id="best_practices_te"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.best_practices_te
                                                })}
                                            />
                                            <span className="text-danger">{errors.best_practices_te}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Best Practices Gallery(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                         <textarea  className={classnames("form-control", {
                                                    invalid: errors.best_practices_gallery_te
                                                })} id="best_practices_gallery_te" 

                                                 value={this.state.best_practices_gallery_te} onChange={this.onChange} />
    
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Time To Grow(Telugu)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time_to_grow_te}
                                                id="time_to_grow_te"
                                                type="text"
                                                error={errors.time_to_grow_te}
                                                className={classnames("form-control", {
                                                    invalid: errors.time_to_grow_te
                                                })} />
                                            <span className="text-danger">{errors.time_to_grow_te}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Main Stage Title(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.main_stage_title_mr}
                                                id="main_stage_title_mr"
                                                type="text"
                                                error={errors.main_stage_title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.main_stage_title_mr
                                                })} />
                                            <span className="text-danger">{errors.main_stage_title_mr}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_mr}
                                                id="title_mr"
                                                type="text"
                                                error={errors.title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_mr
                                                })} />
                                            <span className="text-danger">{errors.title_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_mr">Description(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.description_mr
                                                })} id="description_mr"  
                                             value={this.state.description_mr} onChange={this.onChange} />
                                          
                     
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title_mr">Video Title(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_title_mr"
                                                type="text"
                                                error={errors.video_title_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_title_mr
                                                })} />
                                            <span className="text-danger">{errors.video_title_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_mr">Video Url(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_mr"
                                                type="text"
                                                error={errors.video_url_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_mr
                                                })} />
                                            <span className="text-danger">{errors.video_url_mr}</span>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_image_mr">Video Image(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.video_image_mr}
                                                id="video_image_mr"
                                                type="text"
                                                error={errors.video_image_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_image_mr
                                                })} />
                                            <span className="text-danger">{errors.video_image_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_mr">Possible Problems(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                             <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_mr
                                                })} id="possible_problems_mr" 

                                                 value={this.state.possible_problems_mr} onChange={this.onChange} />
                            
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_gallery_mr">Possible Problems Gallery(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                          <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_gallery_mr
                                                })} id="possible_problems_gallery_mr" 

                                                 value={this.state.possible_problems_gallery_mr} onChange={this.onChange} />

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="best_practices_mr">Best Practices(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.best_practices_mr}
                                                error={errors.best_practices_mr}
                                                id="best_practices_mr"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.best_practices_mr
                                                })}
                                            />
                                            <span className="text-danger">{errors.best_practices_mr}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Best Practices Gallery(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                           <textarea  className={classnames("form-control", {
                                                    invalid: errors.best_practices_gallery_mr
                                                })} id="best_practices_gallery_mr" 

                                                 value={this.state.best_practices_gallery_mr} onChange={this.onChange} />

                                        
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Time To Grow(Marathi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time_to_grow_mr}
                                                id="time_to_grow_mr"
                                                type="text"
                                                error={errors.time_to_grow_mr}
                                                className={classnames("form-control", {
                                                    invalid: errors.time_to_grow_mr
                                                })} />
                                            <span className="text-danger">{errors.time_to_grow_mr}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Main Stage Title(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.main_stage_title_pa}
                                                id="main_stage_title_pa"
                                                type="text"
                                                error={errors.main_stage_title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.main_stage_title_pa
                                                })} />
                                            <span className="text-danger">{errors.main_stage_title_pa}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title_pa}
                                                id="title_pa"
                                                type="text"
                                                error={errors.title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.title_pa
                                                })} />
                                            <span className="text-danger">{errors.title_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description_pa">Description(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">


                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.description_pa
                                                })} id="description_pa"  
                                             value={this.state.description_pa} onChange={this.onChange} />
                                          
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_title_pa">Video Title(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_title_pa"
                                                type="text"
                                                error={errors.video_title_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_title_pa
                                                })} />
                                            <span className="text-danger">{errors.video_title_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_url_pa">Video Url(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                id="video_url_pa"
                                                type="text"
                                                error={errors.video_url_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_url_pa
                                                })} />
                                            <span className="text-danger">{errors.video_url_pa}</span>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="video_image_pa">Video Image(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.video_image_pa}
                                                id="video_image_pa"
                                                type="text"
                                                error={errors.video_image_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.video_image_pa
                                                })} />
                                            <span className="text-danger">{errors.video_image_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_pa">Possible Problems(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                               <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_pa
                                                })} id="possible_problems_pa" 

                                                 value={this.state.possible_problems_pa} onChange={this.onChange} />
                                            </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="possible_problems_gallery_pa">Possible Problems Gallery(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">

                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.possible_problems_gallery_pa
                                                })} id="possible_problems_gallery_pa" 

                                                 value={this.state.possible_problems_gallery_pa} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="best_practices_pa">Best Practices(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.best_practices_pa}
                                                error={errors.best_practices_pa}
                                                id="best_practices_pa"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.best_practices_pa
                                                })}
                                            />
                                            <span className="text-danger">{errors.best_practices_pa}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Best Practices Gallery(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea  className={classnames("form-control", {
                                                    invalid: errors.best_practices_gallery_pa
                                                })} id="best_practices_gallery_pa" 

                                                 value={this.state.best_practices_gallery_pa} onChange={this.onChange} />
   
                                        
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Time To Grow(Punjabi)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time_to_grow_pa}
                                                id="time_to_grow_pa"
                                                type="text"
                                                error={errors.time_to_grow_pa}
                                                className={classnames("form-control", {
                                                    invalid: errors.time_to_grow_pa
                                                })} />
                                            <span className="text-danger">{errors.time_to_grow_pa}</span>
                                        </div>
                                    </div>
     
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} name="status" id="status">
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-pop"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Pop
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PopAddModal.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(PopAddModal));
