import React from 'react'
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

class PushNotificationViewModal extends React.Component {

    constructor() {
        super();
        this.state = {
            total_users: "",
            successful: "",
            failed: "",
            title: "",
            body: "",
            image: "",
            deeplink: "",
            deeplink_id: "",
            date: undefined,
            scheduleDateTime: undefined,
            status: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log("33 working");
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (!_.isEqual(this.props.record, nextProps.record)) {
            console.log(nextProps.record)
            this.setState({
                ...nextProps.record,
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-view-notification-history-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onAppdevicetokenUpdate = e => {
        let { errors } = this.state
        if (this.state.devicetoken === '') {
            errors.devicetoken = "This field is required"
        } else {
            delete errors.devicetoken
        }


        if (isEmpty(errors)) {
   
            const formData = {
                devicetoken: this.state.devicetoken,
                location: this.state.location,
                device_details: this.state.device_details,
                language: this.state.language,
                customertype: this.state.customertype,
                mobile: this.state.mobile,
                app_register: this.state.app_register,
                otp_status: this.state.otp_status,
                otp_submit: this.state.otp_submit,
                customerdetailsapi: this.state.customerdetailsapi,
                
            };

            axios.post('/api/appdevicetoken-update', formData)
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        devicetoken: "",
                        location: "",
                        device_details: "",
                        language: "",
                        customertype: "",
                        mobile: "",
                        app_register: "",
                        otp_status: "",
                        otp_submit: "",
                        customerdetailsapi: "",
                    })
                    $('#update-appdevicetoken-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };
    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    render() {
        console.log("117 working");
        console.log(this.state)
        const { errors } = this.state;
        return (
            <div>
                <div className="modal" id="view-push-notification-modal-history" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">View Notification History</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAppdevicetokenUpdate} id="add-appdevicetoken" encType="multipart/form-data">
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.date}
                                                id="date"
                                                type="text"
                                                error={errors.date}
                                                className={classnames("form-control", {
                                                    invalid: errors.date
                                                })} />
                                            <span className="text-danger">{errors.date}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Scheduled Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.scheduleDateTime}
                                                id="scheduleDateTime"
                                                type="text"
                                                error={errors.scheduleDateTime}
                                                className={classnames("form-control", {
                                                    invalid: errors.scheduleDateTime
                                                })} />
                                            <span className="text-danger">{errors.scheduleDateTime}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Total Users</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.total_users}
                                                id="total_users"
                                                type="text"
                                                error={errors.total_users}
                                                className={classnames("form-control", {
                                                    invalid: errors.total_users
                                                })} />
                                            <span className="text-danger">{errors.total_users}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Successful</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.successful}
                                                id="successful"
                                                type="text"
                                                error={errors.successful}
                                                className={classnames("form-control", {
                                                    invalid: errors.successful
                                                })} />
                                            <span className="text-danger">{errors.successful}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Failed</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.failed}
                                                id="failed"
                                                type="text"
                                                error={errors.failed}
                                                className={classnames("form-control", {
                                                    invalid: errors.failed
                                                })} />
                                            <span className="text-danger">{errors.failed}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.image}
                                                id="image"
                                                type="text"
                                                error={errors.image}
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })} />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Body</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.body}
                                                id="body"
                                                type="text"
                                                error={errors.body}
                                                className={classnames("form-control", {
                                                    invalid: errors.body
                                                })} />
                                            <span className="text-danger">{errors.body}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.deeplink}
                                                id="deeplink"
                                                type="text"
                                                error={errors.deeplink}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink
                                                })} />
                                            <span className="text-danger">{errors.deeplink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Deeplink Id</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.deeplink_id}
                                                id="deeplink_id"
                                                type="text"
                                                error={errors.deeplink_id}
                                                className={classnames("form-control", {
                                                    invalid: errors.deeplink_id
                                                })} />
                                            <span className="text-danger">{errors.deeplink_id}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.status}
                                                id="status"
                                                type="text"
                                                error={errors.status}
                                                className={classnames("form-control", {
                                                    invalid: errors.status
                                                })} />
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">User Id</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.user_ids}
                                                id="user_ids"
                                                type="text"
                                                error={errors.user_ids}
                                                className={classnames("form-control", {
                                                    invalid: errors.user_ids
                                                })} />
                                            <span className="text-danger">{errors.user_ids}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(PushNotificationViewModal));
