import React from 'react'
import classnames from "classnames";
import _ from "lodash";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


class ResumePauseGeoDataModal extends React.Component {
    constructor() {
        super();
        this.state = {
            se_mobile : "",
            isLoding: false,
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.record, nextProps.record)) {
            // console.log(nextProps.record)
            this.setState({
                ...nextProps.record,
            });
       }
            if (!_.isEqual(this.props.record, nextProps.record)) {
                // console.log(nextProps.record)
                this.setState({
                    ...nextProps.record,
                });
           }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#pause-geodata-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onResumePause= e => {
        e.preventDefault();
       if(this.state.isPaused == true){
        this.setState({isLoding: true})
        axios
        .post("https://us-central1-farmbase-b2f7e.cloudfunctions.net/resumeFieldMonitoring", { "UID": "3slpdq65yYXR6M9zDU2I3Ng6kRD2", "FieldID": this.state.FieldID})
        .then(res => {
            if (res.status === 200) {
                axios
                .post("/api/geodata-resume", { _id: this.state._id })
                .then(res => {
                    if (res.status === 200) {
                        this.props.getData();
                        $('#pause-geodata-modal').modal('hide');
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                        })
                    }
                    this.setState({isLoding: false})
                })
                .catch((e)=>                     this.setState({isLoding: false})
                );
            }
        })
        .catch((e)=>                     this.setState({isLoding: false})
        );
       } else{
        this.setState({isLoding: true})
        axios
        .post("https://us-central1-farmbase-b2f7e.cloudfunctions.net/pauseFieldMonitoring", { "UID": "3slpdq65yYXR6M9zDU2I3Ng6kRD2", "FieldID": this.state.FieldID})
        .then(res => {
            if (res.status === 200) {
                axios
                .post("/api/geodata-pause", { _id: this.state._id })
                .then(res => {
                    if (res.status === 200) {
                        this.props.getData();
                        $('#pause-geodata-modal').modal('hide');
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                        })
                    }
                    this.setState({isLoding: false})
                })
                .catch((e)=>                    
                 this.setState({isLoding: false})
                );
            }
        })
        .catch((e)=>                     
        this.setState({isLoding: false})
        );
     
       }
     

    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="pause-geodata-modal" data-reset="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title">Add Pop</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div> */}
                            <div className="modal-body">
                             
                            </div>
                            <h6 className="mt-2 text-primary">Are you sure want to {this.state.isPaused == true? "resume" : "pause"} this field?</h6>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-pop"
                                    type="submit"
                                    onClick={this.state.isLoding ? "" :this.onResumePause} 
                                    className="btn btn-primary">
                                    {this.state.isLoding ? "Submitting": "Confirm"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    

}
ResumePauseGeoDataModal.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(ResumePauseGeoDataModal));