import React from 'react'
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

class AppdevicetokenUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            devicetoken: "",
            location: "",
            device_details: "",
            language: "",
            customertype: "",
            mobile: "",
            app_register: "",
            otp_status: "",
            otp_submit: "",
            customerdetailsapi: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (!_.isEqual(this.props.record, nextProps.record)) {
            // console.log(nextProps.record)
            this.setState({
                ...nextProps.record,
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-appdevicetoken-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onAppdevicetokenUpdate = e => {
        let { errors } = this.state
        if (this.state.devicetoken === '') {
            errors.devicetoken = "This field is required"
        } else {
            delete errors.devicetoken
        }


        if (isEmpty(errors)) {
   
            const formData = {
                devicetoken: this.state.devicetoken,
                location: this.state.location,
                device_details: this.state.device_details,
                language: this.state.language,
                customertype: this.state.customertype,
                mobile: this.state.mobile,
                app_register: this.state.app_register,
                otp_status: this.state.otp_status,
                otp_submit: this.state.otp_submit,
                customerdetailsapi: this.state.customerdetailsapi,
                
            };

            axios.post('/api/appdevicetoken-update', formData)
                .then((response) => {
                    this.props.getData();
                    this.setState({
                        errors: {},
                        devicetoken: "",
                        location: "",
                        device_details: "",
                        language: "",
                        customertype: "",
                        mobile: "",
                        app_register: "",
                        otp_status: "",
                        otp_submit: "",
                        customerdetailsapi: "",
                    })
                    $('#update-appdevicetoken-modal').modal('hide');
                    toast(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }
    };
    onSelect = (selectedList, selectedItem) => {
        this.setState({ store_ids: selectedList })
    }

    render() {
        console.log(this.state)
        const { errors } = this.state;
        return (
            <div>
                <div className="modal" id="update-appdevicetoken-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">View Appdevicetoken</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAppdevicetokenUpdate} id="add-appdevicetoken" encType="multipart/form-data">
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">ID</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state._id}
                                                id="_id"
                                                type="text"
                                                error={errors._id}
                                                className={classnames("form-control", {
                                                    invalid: errors._id
                                                })} />
                                            <span className="text-danger">{errors._id}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Devicetoken</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.devicetoken}
                                                id="devicetoken"
                                                type="text"
                                                error={errors.devicetoken}
                                                className={classnames("form-control", {
                                                    invalid: errors.devicetoken
                                                })} />
                                            <span className="text-danger">{errors.devicetoken}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Location</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.location}
                                                id="locaction"
                                                type="text"
                                                error={errors.location}
                                                className={classnames("form-control", {
                                                    invalid: errors.location
                                                })} />
                                            <span className="text-danger">{errors.location}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Device  Details</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.device_details}
                                                id="device_details"
                                                type="text"
                                                error={errors.device_details}
                                                className={classnames("form-control", {
                                                    invalid: errors.device_details
                                                })} />
                                            <span className="text-danger">{errors.device_details}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Language</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.language}
                                                id="language"
                                                type="text"
                                                error={errors.language}
                                                className={classnames("form-control", {
                                                    invalid: errors.language
                                                })} />
                                            <span className="text-danger">{errors.language}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Customer Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.customertype}
                                                id="customertype"
                                                type="text"
                                                error={errors.customertype}
                                                className={classnames("form-control", {
                                                    invalid: errors.customertype
                                                })} />
                                            <span className="text-danger">{errors.customertype}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Mobile</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.mobile}
                                                id="mobile"
                                                type="text"
                                                error={errors.mobile}
                                                className={classnames("form-control", {
                                                    invalid: errors.mobile
                                                })} />
                                            <span className="text-danger">{errors.mobile}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Otp Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.otp_status}
                                                id="otp_status"
                                                type="text"
                                                error={errors.otp_status}
                                                className={classnames("form-control", {
                                                    invalid: errors.otp_status
                                                })} />
                                            <span className="text-danger">{errors.otp_status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Otp Submit</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.otp_submit}
                                                id="otp_submit"
                                                type="text"
                                                error={errors.otp_submit}
                                                className={classnames("form-control", {
                                                    invalid: errors.otp_submit
                                                })} />
                                            <span className="text-danger">{errors.otp_submit}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">App Register</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.app_register}
                                                id="app_register"
                                                type="text"
                                                error={errors.app_register}
                                                className={classnames("form-control", {
                                                    invalid: errors.app_register
                                                })} />
                                            <span className="text-danger">{errors.app_register}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Customer Details Api</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input readOnly
                                                onChange={this.onChange}
                                                value={this.state.customerdetailsapi}
                                                id="customerdetailsapi"
                                                type="text"
                                                error={errors.customerdetailsapi}
                                                className={classnames("form-control", {
                                                    invalid: errors.customerdetailsapi
                                                })} />
                                            <span className="text-danger">{errors.customerdetailsapi}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(AppdevicetokenUpdateModal));
